import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        color: 'white',
        p: 2,
        mt: 'auto',
        textAlign: 'center',
        borderTop: '3px solid #000',
      }}
    >
      <Typography variant="body1">© 2024 ErmoApp. All rights reserved.</Typography>
    </Box>
  );
};

export default Footer;
