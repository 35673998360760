import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  CircularProgress,
  CssBaseline,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Tooltip,
  Collapse,
} from '@mui/material';
import { styled, useTheme, alpha } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import PlaceBet from '../components/PlaceBet';
import DownloadButton from '../components/DownloadButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { WalletContext } from '../WalletProvider';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'; // AI Icon
import ReceiptIcon from '@mui/icons-material/Receipt'; // Transactions Icon
import CloseIcon from '@mui/icons-material/Close'; // Close Icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// ---------------------- Styled Components ----------------------

// Elegant Button Styling using the new red color
const ElegantButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ff301a', // New red color
  color: theme.palette.common.white,
  fontFamily: "'Schoolbell', cursive",
  border: `2px solid ${theme.palette.common.black}`,
  boxShadow: theme.shadows[2],
  minWidth: '100px',
  padding: theme.spacing(0.75, 1.5),
  fontSize: '1rem',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: '#d92916', // Darker shade for hover
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '80px',
    padding: theme.spacing(0.5, 1),
    fontSize: '0.9rem',
  },
}));

// Secondary Elegant Button Styling
const SecondaryElegantButton = styled(Button)(({ theme }) => ({
  backgroundColor: alpha('#ff301a', 0.8), // Semi-transparent new red color
  color: theme.palette.common.white,
  fontFamily: "'Schoolbell', cursive",
  border: `2px solid ${theme.palette.common.black}`,
  boxShadow: theme.shadows[1],
  minWidth: '100px',
  padding: theme.spacing(0.5, 1),
  fontSize: '0.9rem',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: alpha('#d92916', 0.9),
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '80px',
    padding: theme.spacing(0.5, 1),
    fontSize: '0.8rem',
  },
}));

// Icon Button Styling
const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: '#ff301a', // New red color
  transition: 'color 0.3s',
  '&:hover': {
    color: '#d92916', // Darker shade for hover
  },
}));

// App Container with Wallpaper Background
const AppContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
  backgroundImage: 'url(/path-to-your-wallpaper.jpg)', // Replace with your wallpaper path
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

// Main Content Area
const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

// Top Bar Container for Buttons and Filters
const TopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'nowrap', // Ensure items stay on the same line
  marginBottom: theme.spacing(4),
}));

// Filter and Action Buttons Container
const FilterActionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  flexWrap: 'nowrap', // Ensure items stay on the same line
  width: '100%',
  justifyContent: 'space-between',
}));

// Balance Display Container (Semi-Transparent Background)
const BalanceContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),
  backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent to show wallpaper
  borderRadius: theme.shape.borderRadius,
}));

// Asset Card Styling (Semi-Transparent Background)
const AssetCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  backgroundColor: 'rgba(255, 255, 255, 0.3)', // Semi-transparent
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none', // Removed shadow to avoid white blocks
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

// Circular Image Component
const CircularImage = styled('img')(({ size }) => ({
  width: size || 32,
  height: size || 32,
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: 12, // Space between image and text
}));

// Styled Dialog with Transparent Background
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius * 2,
    border: `2px solid ${theme.palette.grey[400]}`,
    boxShadow: theme.shadows[4],
    backgroundColor: 'rgba(255, 255, 255, 0.95)', // Slight transparency
    width: '100%',
    maxWidth: '600px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      padding: theme.spacing(1),
    },
  },
}));

// Chat Message Container
const ChatMessageContainer = styled(Box)(({ theme, sender }) => ({
  display: 'flex',
  justifyContent: sender === 'user' ? 'flex-end' : 'flex-start',
  marginBottom: theme.spacing(1),
}));

// Chat Bubble Styling
const ChatBubble = styled(Box)(({ theme, sender }) => ({
  backgroundColor:
    sender === 'user' ? '#ff301a' : alpha('#ff301a', 0.8), // New red color
  color: theme.palette.common.white,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: '80%',
  fontFamily: "'Schoolbell', cursive",
  wordWrap: 'break-word', // Ensure long messages wrap
}));

// Transaction List Item
const TransactionListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.85)', // Slightly more opaque
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  boxShadow: theme.shadows[1],
  wordWrap: 'break-word', // Ensure content wraps within the box
  overflowWrap: 'break-word',
}));

// ---------------------- Theme Configuration ----------------------

// Adjusted themeConfig to match the new red color
const themeConfig = createTheme({
  palette: {
    primary: {
      main: '#ff301a', // New red color
      dark: '#d92916', // Darker shade for hover effects
    },
    secondary: {
      main: '#f44336', // You can adjust this if needed
    },
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: "'Schoolbell', cursive",
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 1px 3px rgba(0,0,0,0.2)', // shadow[1]
    '0px 3px 6px rgba(0,0,0,0.3)', // shadow[2]
    '0px 10px 20px rgba(0,0,0,0.19)', // shadow[3]
    '0px 14px 28px rgba(0,0,0,0.25)', // shadow[4]
    // Add more shadows as needed
  ],
});

// ---------------------- Main Bet Page Component ----------------------

const BetPage = () => {
  // Destructure lucid and isInitializing from WalletContext
  const { lucid, walletAddress, isConnected, isInitializing, connectWallet, disconnectWallet } = useContext(WalletContext);

  // State variables
  const [betInfo, setBetInfo] = useState([]); // Added betInfo state
  const [selectedTopic, setSelectedTopic] = useState('All');
  const [chatOpen, setChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [balances, setBalances] = useState({
    ADA: 0,
    USDM: 0,
    ERMO: 0,
    SNEK: 0,
    BabySNEK: 0,
    FREN: 0, // Added FREN balance
    GLDSNEK: 0, // Added GLDSNEK balance
    BABYDONZO: 0, // Added BABYDONZO balance
  });
  const [transactionOpen, setTransactionOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [desktopPopupOpen, setDesktopPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const [selectedBetFilter, setSelectedBetFilter] = useState('All');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('All');

  // New state for loading indicator in chat
  const [isChatLoading, setIsChatLoading] = useState(false);

  // State to manage expanded transactions
  const [expandedTransactions, setExpandedTransactions] = useState({});

  const chatEndRef = useRef(null);

  const currentTheme = useTheme(); // Added useTheme
  const isMobile = useMediaQuery(currentTheme.breakpoints.down('sm')); // Updated to use currentTheme

  // API Keys
  const blockfrostApiKey = 'mainnetKsDvlA00TQW8E0FVTYIto5WWz93lS5Xe'; // Hardcoded API Key
  const chatGptApiKey = process.env.REACT_APP_CHATGPT_API_KEY; // It's recommended to keep this in .env

  // ---------------------- Asset Mapping ----------------------

  // Centralized mapping for all assets
  const assetDetails = {
    ADA: {
      unit: 'lovelace',
      name: 'ADA',
      image: '/ada.png', // Place ada.png in public folder
      policyId: '',
      fingerprint: '',
    },
    USDM: {
      unit: 'c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad0014df105553444d',
      name: 'USDM',
      image: '/usdm.png', // Place usdm.png in public folder
      policyId: 'c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad',
      fingerprint: '',
    },
    ERMO: {
      unit: 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f',
      name: 'ERMO',
      image: '/ermo.png', // Place ermo.png in public folder
      policyId: 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f6',
      fingerprint: '',
    },
    SNEK: {
      unit: '279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f534e454b',
      name: 'SNEK',
      image: '/snek.png', // Place snek.png in public folder
      policyId: '279c909f348e533da5808898f87f9a14bb2c3dfbb',
      fingerprint: '',
    },
    BabySNEK: {
      unit: '7507734918533b3b896241b4704f3d4ce805256b01da6fcede43043642616279534e454b',
      name: 'BabySNEK',
      image: '/bbsnek.png', // Place bbsnek.png in public folder
      policyId: '7507734918533b3b896241b4704f3d4ce805256b01da6fcede430436',
      fingerprint: '',
    },
    FREN: {
      unit: 'fc11a9ef431f81b837736be5f53e4da29b9469c983d07f321262ce614652454e',
      name: 'FREN',
      image: '/fren.png', // Place fren.png in public folder
      policyId: 'fc11a9ef431f81b837736be5f53e4da29b9469c983d07f321262ce614',
      fingerprint: '',
    },
    GLDSNEK: {
      unit: 'cdbbe391853676285131fe2de250e274dbeb5f9d98344e86c7b383d9474c44534e454b',
      name: 'GLDSNEK',
      image: '/gldsnek.png', // Place gldsnek.png in public folder
      policyId: 'cdbbe391853676285131fe2de250e274dbeb5f9d98344e86c7b383d9',
      fingerprint: 'asset1qfe3d8dfma2avcjyc3dn4d8jvdln0dffh8s25r',
    },
    BABYDONZO: {
      unit: '7f149ffd7b3311da196e7af94abdcb7f4ab889677ec1ecb888e32dac42414259444f4e5a4f', // Corrected unit
      name: 'BABYDONZO',
      image: '/babydonzo.png', // Place babydonzo.png in public folder
      policyId: '7f149ffd7b3311da196e7af94abdcb7f4ab889677ec1ecb888e32dac',
      fingerprint: 'asset1usv2gvmvfgxp9fyjydpe6q5qn99q36x2js0ga3',
    },
  };

  // ---------------------- Fetch Functions ----------------------

  // Fetch betInfo on component mount
  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/betInfo.json`)
      .then(response => response.json())
      .then(data => {
        setBetInfo(data);
        console.log('Loaded betInfo:', data);
      })
      .catch(error => console.error("Error loading betInfo:", error));
  }, []);

  // Fetch balances and transactions when wallet connects and betInfo is loaded
  useEffect(() => {
    if (isConnected && walletAddress && betInfo.length > 0) {
      fetchBalances();
      fetchTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, walletAddress, betInfo]);

  // Show desktop popup if not on mobile
  useEffect(() => {
    if (!isMobile) {
      setDesktopPopupOpen(true);
    }
  }, [isMobile]);

  // Scroll to the latest chat message
  useEffect(() => {
    if (chatOpen) {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages, chatOpen]);

  // ---------------------- API Functions ----------------------

  // Fetch wallet balances
  const fetchBalances = async () => {
    if (!lucid) {
      console.error('Lucid is not initialized.');
      return;
    }

    try {
      const utxos = await lucid.wallet.getUtxos();
      console.log('Fetched UTXOs:', utxos);
      let adaBalance = 0;
      let usdmBalance = 0;
      let ermoBalance = 0;
      let snekBalance = 0;
      let babySnekBalance = 0; // Initialize BabySNEK balance
      let frenBalance = 0; // Initialize FREN balance
      let gldsnekBalance = 0; // Initialize GLDSNEK balance
      let babydonzoBalance = 0; // Initialize BABYDONZO balance

      utxos.forEach((utxo) => {
        adaBalance += parseInt(utxo.assets['lovelace'] || 0, 10) / 1_000_000;

        Object.entries(utxo.assets).forEach(([key, amount]) => {
          // Loop through assetDetails to find matching asset
          Object.values(assetDetails).forEach((asset) => {
            if (asset.unit === 'lovelace') {
              // ADA is handled separately
              return;
            }
            if (key.includes(asset.unit)) {
              if (asset.name === 'BabySNEK') {
                babySnekBalance += parseInt(amount, 10);
              } else if (asset.name === 'FREN') {
                frenBalance += parseInt(amount, 10);
              } else if (asset.name === 'GLDSNEK') {
                gldsnekBalance += parseInt(amount, 10);
              } else if (asset.name === 'BABYDONZO') {
                babydonzoBalance += parseInt(amount, 10);
              } else {
                switch (asset.name) {
                  case 'USDM':
                    usdmBalance += parseInt(amount, 10) / 1_000_000;
                    break;
                  case 'ERMO':
                    ermoBalance += parseInt(amount, 10);
                    break;
                  case 'SNEK':
                    snekBalance += parseInt(amount, 10);
                    break;
                  default:
                    break;
                }
              }
            }
          });
        });
      });

      setBalances({
        ADA: adaBalance,
        USDM: usdmBalance,
        ERMO: ermoBalance,
        SNEK: snekBalance,
        BabySNEK: babySnekBalance,
        FREN: frenBalance, // Added FREN balance
        GLDSNEK: gldsnekBalance, // Added GLDSNEK balance
        BABYDONZO: babydonzoBalance, // Added BABYDONZO balance
      });

      console.log('Updated Balances:', {
        ADA: adaBalance,
        USDM: usdmBalance,
        ERMO: ermoBalance,
        SNEK: snekBalance,
        BabySNEK: babySnekBalance,
        FREN: frenBalance, // Log FREN balance
        GLDSNEK: gldsnekBalance, // Log GLDSNEK balance
        BABYDONZO: babydonzoBalance, // Log BABYDONZO balance
      });
    } catch (error) {
      console.error('Error fetching balances:', error);
      alert('An error occurred while fetching balances. Please check the console for more details.');
    }
  };

  // Fetch transactions related to the connected wallet
  const fetchTransactions = async () => {
    if (!lucid) {
      console.error('Lucid is not initialized.');
      return;
    }

    try {
      if (!walletAddress) {
        console.warn('No wallet address connected.');
        return;
      }

      console.log('Fetching transactions for connected wallet:', walletAddress);

      let allTxs = [];
      const betWalletAddresses = new Set(
        betInfo.map((bet) => bet.walletAddress.toLowerCase())
      );
      console.log('Bet Wallet Addresses:', betWalletAddresses);

      setIsLoading(true);

      const concurrencyLimit = 5;
      const queue = [...betInfo];
      let activePromises = [];

      const fetchBatch = async () => {
        if (queue.length === 0) return;

        while (activePromises.length < concurrencyLimit && queue.length > 0) {
          const bet = queue.shift();
          const betWalletAddress = bet.walletAddress.toLowerCase();
          console.log(`Fetching transactions for bet wallet: ${betWalletAddress}`);

          const fetchAllPages = async () => {
            let page = 1;
            const limit = 100;
            let hasMore = true;

            while (hasMore) {
              try {
                const txResponse = await axios.get(
                  `https://cardano-mainnet.blockfrost.io/api/v0/addresses/${betWalletAddress}/transactions`,
                  {
                    headers: {
                      project_id: blockfrostApiKey,
                    },
                    params: {
                      page,
                      count: limit,
                    },
                  }
                );

                const txData = txResponse.data;
                console.log(
                  `Fetched ${txData.length} transactions for ${betWalletAddress} on page ${page}`
                );

                if (txData.length === 0) {
                  hasMore = false;
                  break;
                }

                allTxs = [...allTxs, ...txData];

                if (txData.length < limit) {
                  hasMore = false;
                } else {
                  page += 1;
                }
              } catch (error) {
                console.error(
                  `Error fetching transactions for ${betWalletAddress} on page ${page}:`,
                  error.response ? error.response.data : error.message
                );
                hasMore = false;
              }
            }
          };

          const promise = fetchAllPages();
          activePromises.push(promise);

          promise.then(() => {
            activePromises = activePromises.filter((p) => p !== promise);
          });
        }

        await Promise.all(activePromises);
        await fetchBatch();
      };

      await fetchBatch();

      console.log('Total Transactions Fetched from all bet wallets:', allTxs.length);

      const incomingTxs = [];
      const uniqueTxHashes = new Set();

      for (let tx of allTxs) {
        if (uniqueTxHashes.has(tx.tx_hash)) {
          console.log(`Duplicate transaction found: ${tx.tx_hash}, skipping.`);
          continue;
        }
        uniqueTxHashes.add(tx.tx_hash);

        console.log(`Processing transaction: ${tx.tx_hash}`);

        let txDetails = {};
        try {
          const txDetailsResponse = await axios.get(
            `https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}`,
            {
              headers: {
                project_id: blockfrostApiKey,
              },
            }
          );
          txDetails = txDetailsResponse.data;
          console.log(`Transaction Details for ${tx.tx_hash}:`, txDetails);
        } catch (error) {
          console.error(`Error fetching details for transaction ${tx.tx_hash}:`, error.response ? error.response.data : error.message);
          continue;
        }

        let utxos = {};
        try {
          const txUTXOsResponse = await axios.get(
            `https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}/utxos`,
            {
              headers: {
                project_id: blockfrostApiKey,
              },
            }
          );
          utxos = txUTXOsResponse.data;
          console.log(`UTXOs for tx ${tx.tx_hash}:`, utxos);
        } catch (utxosError) {
          console.error(`Error fetching UTXOs for transaction ${tx.tx_hash}:`, utxosError.response ? utxosError.response.data : utxosError.message);
          continue;
        }

        const isSender =
          utxos.inputs &&
          utxos.inputs.some(
            (input) => input.address.toLowerCase() === walletAddress.toLowerCase()
          );

        if (isSender) {
          console.log(`Connected wallet is the sender for transaction ${tx.tx_hash}`);

          let metadata = [];
          try {
            const txMetadataResponse = await axios.get(
              `https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}/metadata`,
              {
                headers: {
                  project_id: blockfrostApiKey,
                },
              }
            );
            metadata = txMetadataResponse.data;
            console.log(`Metadata for tx ${tx.tx_hash}:`, metadata);
          } catch (metaError) {
            console.warn(`No metadata for transaction ${tx.tx_hash}`);
          }

          const relatedOutputs = utxos.outputs.filter((output) =>
            betWalletAddresses.has(output.address.toLowerCase())
          );

          if (relatedOutputs.length > 0) {
            relatedOutputs.forEach((output) => {
              const assets = output.amount.map((amount) => {
                if (amount.unit === 'lovelace') {
                  return { asset: 'ADA', amount: amount.quantity / 1_000_000 };
                } else {
                  // Find asset in assetDetails
                  const matchedAsset = Object.values(assetDetails).find(
                    (asset) => amount.unit.includes(asset.unit)
                  );
                  if (matchedAsset) {
                    return { asset: matchedAsset.name, amount: amount.quantity };
                  }
                }
                return null;
              }).filter(Boolean);

              const nonAdaAssets = assets.filter((asset) => asset.asset !== 'ADA');
              const displayAssets = nonAdaAssets.length > 0 ? nonAdaAssets : assets;

              const timestamp = new Date(txDetails.block_time * 1000).toLocaleString();

              const betInfoData = metadata.length > 0 ? metadata[0]?.json_metadata : null;
              const betId = betInfoData?.betId;
              const associatedBet = betId ? betInfo.find((bet) => bet.id === betId) : null;
              const betTitle = associatedBet?.title || 'Unknown Bet';
              const startDate = associatedBet?.startDate;
              const endDate = associatedBet?.endDate;

              let betStatus = 'Unknown';
              if (startDate && endDate) {
                const now = new Date();
                const start = new Date(startDate);
                const end = new Date(endDate);

                if (now >= start && now <= end) {
                  betStatus = 'Bet Live';
                } else if (now > end) {
                  betStatus = 'Bet Ended';
                } else {
                  betStatus = 'Bet Not Started';
                }
              }

              incomingTxs.push({
                hash: tx.tx_hash,
                sender: walletAddress,
                assets: displayAssets,
                betTitle: betTitle,
                result: betInfoData?.result,
                time: timestamp,
                betStatus: betStatus,
              });
            });
          } else {
            console.log(`No related outputs for transaction ${tx.tx_hash}`);
          }
        } else {
          console.log(`Connected wallet is not the sender for transaction ${tx.tx_hash}`);
        }
      }

      console.log('Incoming Transactions:', incomingTxs);

      incomingTxs.sort((a, b) => new Date(b.time) - new Date(a.time));

      setTransactions(incomingTxs);
    } catch (error) {
      console.error('Error fetching transactions:', error.response ? error.response.data : error.message);
      alert('An error occurred while fetching transactions. Please check the console for more details.');
    } finally {
      setIsLoading(false);
    }
  };

  // Initialize Chat with ERMO AI
  const initializeChat = async () => {
    if (!chatGptApiKey) {
      console.error('ChatGPT API key is missing. Please set REACT_APP_CHATGPT_API_KEY in your .env file.');
      alert('ChatGPT API key is missing. Please contact support.');
      return;
    }

    try {
      setIsChatLoading(true); // Start loading

      // Filter out 'Closed' bets to ensure AI does not get information about them
      const activeBets = betInfo.filter((bet) => bet.type !== 'Closed');

      const detailedBetInfo = activeBets
        .map(
          (bet) =>
            `${bet.title} - Description: ${bet.description || 'N/A'}, Results: ${
              bet.result?.join(', ') || 'N/A'
            }`
        )
        .join('; ');

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${chatGptApiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-4', // Corrected model name
          messages: [
            {
              role: 'system',
              content:
                "You are ERMO AI, a confident, risk-taking betting advisor. You provide direct, assertive recommendations to users, helping them maximize their betting potential without holding back.",
            },
            {
              role: 'system',
              content: `Here is some context about the current webpage: ${document.documentElement.innerText}`,
            },
            {
              role: 'system',
              content: `Currently available bets with details: ${detailedBetInfo}`,
            },
          ],
          max_tokens: 800,
        }),
      });

      const data = await response.json();
      if (data.choices && data.choices.length > 0) {
        let botMessage = data.choices[0].message.content.trim();
        botMessage = botMessage.replace(/\n\s*\n/g, '\n');

        setChatMessages((prev) => [...prev, { sender: 'bot', text: botMessage }]);
      } else {
        setChatMessages((prev) => [
          ...prev,
          { sender: 'bot', text: 'Sorry, I could not generate a response at this time.' },
        ]);
      }
    } catch (error) {
      console.error('Error initializing chat with ERMO AI:', error);
      setChatMessages((prev) => [
        ...prev,
        {
          sender: 'bot',
          text: 'Sorry, something went wrong initializing the chat. Please try again later.',
        },
      ]);
    } finally {
      setIsChatLoading(false); // End loading
    }
  };

  // ---------------------- Event Handlers ----------------------

  // Open chat dialog
  const handleChatBubbleClick = () => {
    setChatOpen(true);
    if (!isChatInitialized) {
      initializeChat();
      setIsChatInitialized(true);
    }
  };

  // Open transaction dialog
  const handleTransactionBubbleClick = () => {
    setTransactionOpen(true);
  };

  // Close chat dialog
  const handleChatClose = () => {
    setChatOpen(false);
  };

  // Close transaction dialog
  const handleTransactionClose = () => {
    setTransactionOpen(false);
  };

  // Handle chat input change
  const handleChatInputChange = (event) => {
    setChatInput(event.target.value);
  };

  // Send chat message
  const handleSendMessage = async () => {
    if (chatInput.trim() !== '') {
      const userMessage = chatInput;
      setChatMessages((prev) => [...prev, { sender: 'user', text: userMessage }]);
      setChatInput('');

      if (!chatGptApiKey) {
        console.error('ChatGPT API key is missing. Please set REACT_APP_CHATGPT_API_KEY in your .env file.');
        setChatMessages((prev) => [
          ...prev,
          { sender: 'bot', text: 'ChatGPT API key is missing. Please contact support.' },
        ]);
        return;
      }

      try {
        setIsChatLoading(true); // Start loading

        // Filter out 'Closed' bets to ensure AI does not get information about them
        const activeBets = betInfo.filter((bet) => bet.type !== 'Closed');

        const detailedBetInfo = activeBets
          .map(
            (bet) =>
              `${bet.title} - Description: ${bet.description || 'N/A'}, Results: ${
                bet.result?.join(', ') || 'N/A'
              }`
          )
          .join('; ');

        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${chatGptApiKey}`,
          },
          body: JSON.stringify({
            model: 'gpt-4', // Corrected model name
            messages: [
              {
                role: 'system',
                content:
                  "You are ERMO AI, a confident, risk-taking betting advisor. You provide direct, assertive recommendations to users, helping them maximize their betting potential without holding back.",
              },
              {
                role: 'system',
                content: `Here is some context about the current webpage: ${document.documentElement.innerText}`,
              },
              {
                role: 'system',
                content: `Currently available bets with details: ${detailedBetInfo}`,
              },
              ...chatMessages.map((msg) => ({
                role: msg.sender === 'user' ? 'user' : 'assistant',
                content: msg.text,
              })),
              { role: 'user', content: userMessage },
            ],
            max_tokens: 800,
          }),
        });

        const data = await response.json();
        if (data.choices && data.choices.length > 0) {
          let botMessage = data.choices[0].message.content.trim();
          botMessage = botMessage.replace(/\n\s*\n/g, '\n');

          setChatMessages((prev) => [...prev, { sender: 'bot', text: botMessage }]);
        } else {
          setChatMessages((prev) => [
            ...prev,
            { sender: 'bot', text: 'Sorry, I could not generate a response at this time.' },
          ]);
        }
      } catch (error) {
        console.error('Error fetching response from ChatGPT:', error);
        setChatMessages((prev) => [
          ...prev,
          { sender: 'bot', text: 'Sorry, something went wrong. Please try again later.' },
        ]);
      } finally {
        setIsChatLoading(false); // End loading
      }
    }
  };

  // Close desktop popup
  const closeDesktopPopup = () => {
    setDesktopPopupOpen(false);
  };

  // Handle expand/collapse of transaction details
  const handleToggleExpand = (txHash) => {
    setExpandedTransactions((prev) => ({
      ...prev,
      [txHash]: !prev[txHash],
    }));
  };

  // ---------------------- Filtering Logic ----------------------

  const betTypes = ['All', ...new Set(betInfo.map((bet) => bet.type))];
  const betTitles = ['All', ...new Set(betInfo.map((bet) => bet.title))];
  const statusOptions = ['All', 'Bet Live', 'Bet Ended', 'Bet Not Started'];

  const filteredBets =
    selectedTopic === 'All'
      ? betInfo.filter((bet) => bet.type !== 'Closed') // Exclude 'Closed' when 'All' is selected
      : betInfo.filter((bet) => bet.type === selectedTopic);

  const applyFilters = () => {
    let filtered = [...transactions];

    if (selectedBetFilter !== 'All') {
      filtered = filtered.filter((tx) => tx.betTitle === selectedBetFilter);
    }

    if (selectedStatusFilter !== 'All') {
      filtered = filtered.filter((tx) => tx.betStatus === selectedStatusFilter);
    }

    return filtered;
  };

  const filteredTransactions = applyFilters();

  // ---------------------- Render Component ----------------------

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      <AppContainer>
        <MainContent>
          {/* Top Bar with Filter and Buttons */}
          <TopBar>
            <FilterActionContainer>
              {/* Action Buttons */}
              <Box sx={{ display: 'flex', gap: 2 }}>
                {/* AI Button */}
                <Tooltip title="Chat with ERMO AI" arrow>
                  <ElegantButton
                    startIcon={<EmojiPeopleIcon />}
                    onClick={handleChatBubbleClick}
                    aria-label="Chat with ERMO AI"
                  >
                    {!isMobile && 'AI'}
                  </ElegantButton>
                </Tooltip>

                {/* Transaction History Button */}
                <Tooltip title="View Transaction History" arrow>
                  <ElegantButton
                    startIcon={<ReceiptIcon />}
                    onClick={handleTransactionBubbleClick}
                    aria-label="View Transaction History"
                  >
                    {!isMobile && 'Transactions'}
                  </ElegantButton>
                </Tooltip>
              </Box>

              {/* Filter Select */}
              <FormControl
                variant="outlined"
                size="small"
                sx={{ minWidth: 150, flexShrink: 0 }} // Ensure minimum width and prevent shrinking
              >
                <Select
                  value={selectedTopic}
                  onChange={(e) => setSelectedTopic(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Topic Filter' }}
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
                    borderRadius: 1,
                  }}
                >
                  <MenuItem value="All">All Topics</MenuItem>
                  {betTypes.slice(1).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FilterActionContainer>
          </TopBar>

          {/* Balances Below */}
          {isConnected && walletAddress && (
            <BalanceContainer>
              <Grid container spacing={2}>
                {Object.values(assetDetails)
                  .filter((asset) => balances[asset.name] > 0) // Only display assets with balance > 0
                  .map((asset) => (
                    <Grid item xs={6} sm={4} md={3} key={asset.name}>
                      <AssetCard>
                        <CircularImage
                          src={asset.image}
                          alt={asset.name}
                          size={32}
                        />
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontFamily: "'Schoolbell', cursive",
                              fontWeight: 'bold',
                            }}
                          >
                            {asset.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "'Schoolbell', cursive",
                            }}
                          >
                            {balances[asset.name].toLocaleString(undefined, {
                              minimumFractionDigits: asset.name === 'USDM' ? 6 : 2,
                              maximumFractionDigits: asset.name === 'USDM' ? 6 : 2,
                            })}
                          </Typography>
                        </Box>
                      </AssetCard>
                    </Grid>
                  ))}
              </Grid>
            </BalanceContainer>
          )}

          {/* Bets Grid */}
          <Grid
            container
            direction="column" // Changed to single column
            alignItems="center" // Center items horizontally
            spacing={4}
            sx={{
              marginTop: isMobile ? 2.5 : 5,
            }}
          >
            {filteredBets.map((bet) => (
              <Grid item key={bet.id} xs={12} sm={10} md={8} lg={6} sx={{ width: '100%', maxWidth: '600px' }}> {/* Added maxWidth and set width to 100% */}
                <PlaceBet bet={bet} lucid={lucid} walletAddress={walletAddress} />
              </Grid>
            ))}
          </Grid>
        </MainContent>

        {/* Chat Dialog */}
        <StyledDialog open={chatOpen} onClose={handleChatClose}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButtonStyled onClick={handleChatClose} aria-label="Close Chat">
              <CloseIcon />
            </IconButtonStyled>
          </Box>
          <DialogTitle
            sx={{
              fontFamily: "'Schoolbell', cursive",
              textAlign: 'center',
              borderBottom: `2px solid ${themeConfig.palette.grey[300]}`,
            }}
          >
            Chat with ERMO AI
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '400px' }}>
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  padding: 2,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
                  borderRadius: 1,
                  marginBottom: 2,
                }}
              >
                {chatMessages.map((message, index) => (
                  <ChatMessageContainer key={index} sender={message.sender}>
                    <ChatBubble sender={message.sender}>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {message.text}
                      </ReactMarkdown>
                    </ChatBubble>
                  </ChatMessageContainer>
                ))}

                {/* Loading Animation */}
                {isChatLoading && (
                  <ChatMessageContainer sender="bot">
                    <ChatBubble sender="bot">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={16} sx={{ mr: 1 }} />
                        <Typography variant="body2">ERMO AI is typing...</Typography>
                      </Box>
                    </ChatBubble>
                  </ChatMessageContainer>
                )}

                <div ref={chatEndRef} />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={chatInput}
                  onChange={handleChatInputChange}
                  placeholder="Type your message..."
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
                    borderRadius: 1,
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleSendMessage();
                    }
                  }}
                />
                <ElegantButton
                  variant="contained"
                  onClick={handleSendMessage}
                  disabled={isChatLoading}
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                  }}
                >
                  Send
                </ElegantButton>
              </Box>
            </Box>
          </DialogContent>
        </StyledDialog>

        {/* Transaction Dialog */}
        <StyledDialog open={transactionOpen} onClose={handleTransactionClose}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButtonStyled onClick={handleTransactionClose} aria-label="Close Transactions">
              <CloseIcon />
            </IconButtonStyled>
          </Box>
          <DialogTitle
            sx={{
              fontFamily: "'Schoolbell', cursive",
              textAlign: 'center',
              borderBottom: `2px solid ${themeConfig.palette.grey[300]}`,
            }}
          >
            Transaction History
          </DialogTitle>
          <DialogContent>
            {/* Filter Section */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                flexWrap: 'nowrap', // Ensure items stay on the same line
                marginBottom: 2,
              }}
            >
              {/* Bet Filter */}
              <FormControl variant="outlined" size="small" sx={{ minWidth: 150, flexShrink: 0 }}>
                <Select
                  value={selectedBetFilter}
                  onChange={(e) => setSelectedBetFilter(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Bet Filter' }}
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
                    borderRadius: 1,
                  }}
                >
                  <MenuItem value="All">All Bets</MenuItem>
                  {betTitles.slice(1).map((title) => (
                    <MenuItem key={title} value={title}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Status Filter */}
              <FormControl variant="outlined" size="small" sx={{ minWidth: 150, flexShrink: 0 }}>
                <Select
                  value={selectedStatusFilter}
                  onChange={(e) => setSelectedStatusFilter(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Status Filter' }}
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
                    borderRadius: 1,
                  }}
                >
                  <MenuItem value="All">All Statuses</MenuItem>
                  {statusOptions.slice(1).map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Clear Filters Button */}
              <SecondaryElegantButton
                variant="outlined"
                onClick={() => {
                  setSelectedBetFilter('All');
                  setSelectedStatusFilter('All');
                }}
                sx={{
                  fontFamily: "'Schoolbell', cursive",
                }}
              >
                Clear Filters
              </SecondaryElegantButton>
            </Box>

            {/* Transaction List */}
            <List>
              {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <CircularProgress color="primary" />
                </Box>
              ) : filteredTransactions.length > 0 ? (
                filteredTransactions.map((tx, index) => (
                  <React.Fragment key={tx.hash}>
                    <TransactionListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <CircularImage
                          src={assetDetails[tx.assets[0].asset]?.image || '/default.png'}
                          alt={tx.assets[0].asset}
                          size={40}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontFamily: "'Schoolbell', cursive", fontWeight: 'bold' }}
                            >
                              {tx.betTitle}
                            </Typography>
                            <IconButton
                              onClick={() => handleToggleExpand(tx.hash)}
                              size="small"
                              sx={{ color: themeConfig.palette.primary.main }}
                              aria-label="Expand transaction details"
                            >
                              {expandedTransactions[tx.hash] ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </Box>
                        }
                        secondary={
                          <>
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary' }}
                            >
                              <strong>Time:</strong> {tx.time}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary' }}
                            >
                              <strong>Assets:</strong>
                              <List disablePadding>
                                {tx.assets.map((asset, idx) => (
                                  <ListItem key={idx} disableGutters>
                                    <ListItemAvatar>
                                      <CircularImage
                                        src={assetDetails[asset.asset]?.image || '/default.png'}
                                        alt={asset.asset}
                                        size={24}
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={`${asset.amount.toLocaleString(undefined, {
                                        minimumFractionDigits: asset.asset === 'USDM' ? 6 : 2,
                                        maximumFractionDigits: asset.asset === 'USDM' ? 6 : 2,
                                      })} ${asset.asset}`}
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary' }}
                            >
                              <strong>Bet Status:</strong> {tx.betStatus}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary' }}
                            >
                              <strong>Result:</strong> {tx.result || 'No result'}
                            </Typography>
                            {/* Collapsible Transaction Hash and Asset Details */}
                            <Collapse in={expandedTransactions[tx.hash]} timeout="auto" unmountOnExit>
                              <Typography
                                variant="body2"
                                sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary', mt: 1 }}
                              >
                                <strong>Transaction Hash:</strong> {tx.hash}
                              </Typography>
                              {/* Display Policy ID, Fingerprint, and Asset Name for each asset */}
                              <Box sx={{ mt: 1 }}>
                                {tx.assets.map((asset, idx) => {
                                  const assetDetail = assetDetails[asset.asset];
                                  if (assetDetail && assetDetail.policyId) {
                                    return (
                                      <Box key={idx} sx={{ mt: 1, pl: 2 }}>
                                        <Typography
                                          variant="body2"
                                          sx={{ fontFamily: "'Schoolbell', cursive", fontWeight: 'bold' }}
                                        >
                                          {asset.asset} Details:
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{ fontFamily: "'Schoolbell', cursive" }}
                                        >
                                          <strong>Policy ID:</strong> {assetDetail.policyId}
                                        </Typography>
                                        {assetDetail.fingerprint && (
                                          <Typography
                                            variant="body2"
                                            sx={{ fontFamily: "'Schoolbell', cursive" }}
                                          >
                                            <strong>Fingerprint:</strong> {assetDetail.fingerprint}
                                          </Typography>
                                        )}
                                        <Typography
                                          variant="body2"
                                          sx={{ fontFamily: "'Schoolbell', cursive" }}
                                        >
                                          <strong>Asset Name:</strong> {assetDetail.name} ({assetDetail.unit.slice(-14)})
                                        </Typography>
                                      </Box>
                                    );
                                  }
                                  return null;
                                })}
                              </Box>
                            </Collapse>
                          </>
                        }
                      />
                    </TransactionListItem>
                    <Divider component="li" />
                  </React.Fragment>
                ))
              ) : (
                <Typography sx={{ fontFamily: "'Schoolbell', cursive", textAlign: 'center', mt: 2 }}>
                  No transactions found.
                </Typography>
              )}
            </List>

            {/* Download Button */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 2,
              }}
            >
              <DownloadButton transactions={transactions} />
            </Box>
          </DialogContent>
        </StyledDialog>

        {/* Desktop Popup Notice */}
        <StyledDialog
          open={desktopPopupOpen}
          onClose={closeDesktopPopup}
          aria-labelledby="desktop-popup-title"
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButtonStyled onClick={closeDesktopPopup} aria-label="Close Notice">
              <CloseIcon />
            </IconButtonStyled>
          </Box>
          <DialogTitle
            id="desktop-popup-title"
            sx={{
              fontFamily: "'Schoolbell', cursive",
              textAlign: 'center',
              borderBottom: `2px solid ${themeConfig.palette.grey[300]}`,
            }}
          >
            Notice
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                fontFamily: "'Schoolbell', cursive",
                fontSize: '1.1rem',
                textAlign: 'center',
                marginBottom: 2,
              }}
            >
              Enjoy the full experience on mobile! The desktop version is available, but still a work in progress.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ElegantButton
                onClick={closeDesktopPopup}
                variant="contained"
                sx={{
                  fontFamily: "'Schoolbell', cursive",
                }}
              >
                Got it!
              </ElegantButton>
            </Box>
          </DialogContent>
        </StyledDialog>
      </AppContainer>
    </ThemeProvider>
  );
};

export default BetPage;
