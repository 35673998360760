import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const MultiplierExplanationDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>How the Multiplier Works</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          The multiplier shows how much you can win based on how many people bet on the same result.
          The fewer people who bet on it, the higher your potential winnings.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Pick an Asset and a Result:</strong> First, you choose what you want to bet (e.g., ADA, SNEK) and which result you think will happen.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Your Multiplier:</strong> The multiplier is calculated based on how much has been bet by everyone else. The formula looks like this:
        </Typography>
        <Typography variant="body2" gutterBottom>
          Multiplier = (Total Amount Bet on All Results / Amount Bet on Your Result) × 0.97
        </Typography>
        <Typography variant="body1" gutterBottom>
          The 0.97 factor ensures that 3% of the total bets are kept by the system.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Dynamic Changes:</strong> The multiplier changes as more people place bets. If many people bet on the same result as you, your multiplier may go down. If you’re betting on a result that few people choose, your multiplier will stay higher.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Best Multiplier Tip:</strong> The system also shows the Best Multiplier, which is the highest possible multiplier across all results and assets. This helps you spot where you could get the best returns.
        </Typography>
        <Typography variant="body1" gutterBottom>
          In short, the more unique your bet is, the higher the multiplier—and the more you can win!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiplierExplanationDialog;
