/* global BigInt */

import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Box,
  InputAdornment,
  CircularProgress,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { WalletContext } from '../WalletProvider'; // Adjust the import path as needed

// Styled Components

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: '#f9fafc',
  minHeight: '100vh',
  padding: theme.spacing(5, 2),
}));

const MainGrid = styled(Grid)(({ theme }) => ({
  maxWidth: 1200,
  margin: '0 auto',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  backgroundColor: '#ffffff',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(0.5),
  fontSize: '1rem',
  textTransform: 'none',
  color: '#fff',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SmallButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2.5),
  borderRadius: theme.spacing(0.5),
  fontSize: '0.95rem',
  textTransform: 'none',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BoxedAsset = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
  backgroundColor: '#ffffff',
}));

const AssetText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontSize: '1rem',
  color: theme.palette.text.primary,
}));

const TicketCard = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  width: 150,
  textAlign: 'center',
  backgroundColor: '#ffffff',
}));

// Prize Distribution Component
const PrizeDistribution = ({ prizes }) => (
  <StyledCard>
    <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
      Lottery Prize Pool
    </Typography>
    <Grid container spacing={3}>
      {['ERMO', 'ADA'].map((asset) => (
        <Grid item xs={12} sm={6} key={asset}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              alt={asset}
              src={asset === 'ERMO' ? '/ermo.png' : '/ada.png'}
              sx={{ width: 60, height: 60, marginBottom: 1 }}
            />
            {Object.entries(prizes[asset]).map(([key, value]) => (
              <BoxedAsset key={`${asset}-${key}`}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {formatPrizeLabel(key)}:
                </Typography>
                <AssetText>
                  {value !== undefined ? Number(value.toFixed(2)).toLocaleString() : '0'}
                </AssetText>
              </BoxedAsset>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  </StyledCard>
);

// Helper function to format prize labels
const formatPrizeLabel = (key) => {
  switch (key) {
    case 'firstPrize':
      return 'First Prize';
    case 'secondPrize':
      return 'Second Prize';
    case 'thirdPrize':
      return 'Third Prize';
    case 'burn':
      return 'Burn';
    case 'jackpot':
      return 'Jackpot';
    default:
      return key;
  }
};

// Timer Component
const Timer = ({ endTime, onCountdownEnd }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    // Fetch current time from World Time API on initial load
    const fetchTime = async () => {
      const fetchedTime = await fetchCurrentTime();
      setCurrentTime(fetchedTime);
      const calculatedTimeLeft = calculateTimeLeft(fetchedTime, endTime);
      setTimeLeft(calculatedTimeLeft);
    };

    fetchTime();

    const timerInterval = setInterval(() => {
      setCurrentTime((prevTime) => {
        const newTime = new Date(prevTime.getTime() + 1000); // Increment by 1 second
        const calculatedTimeLeft = calculateTimeLeft(newTime, endTime);
        setTimeLeft(calculatedTimeLeft);

        if (!calculatedTimeLeft) {
          clearInterval(timerInterval); // Stop interval if countdown is over
          if (onCountdownEnd) {
            onCountdownEnd(); // Notify parent component
          }
        }

        return newTime;
      });
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup interval on unmount
  }, [endTime, onCountdownEnd]);

  if (!timeLeft) {
    return (
      <StyledCard>
        <Typography variant="h5" sx={{ fontSize: '1.5rem' }}>
          Lottery Ended
        </Typography>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <Typography variant="h5" sx={{ fontSize: '1.5rem', marginBottom: 2 }}>
        Lottery Ends In:
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        {['Days', 'Hours', 'Minutes', 'Seconds'].map((unit, index) => {
          const value = [timeLeft.days, timeLeft.hours, timeLeft.minutes, timeLeft.seconds][index];
          return (
            <Box key={unit} textAlign="center">
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                {String(value).padStart(2, '0')}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {unit}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </StyledCard>
  );
};

// Helper functions for Timer
const fetchCurrentTime = async () => {
  try {
    const response = await axios.get('https://worldtimeapi.org/api/ip');
    const data = response.data;
    return new Date(data.utc_datetime); // Return the UTC time
  } catch (error) {
    console.error('Error fetching current time:', error);
    return new Date(); // Fallback to local time if API fails
  }
};

const calculateTimeLeft = (currentTime, endTime) => {
  const endDate = new Date(endTime).getTime(); // Convert end time to UTC milliseconds
  const currentTimestamp = currentTime.getTime(); // Current UTC time in milliseconds

  const difference = endDate - currentTimestamp; // Time difference in milliseconds

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  } else {
    return null; // Countdown is complete
  }
};

const Lottery = () => {
  const {
    lucid,
    walletAddress,
    isConnected,
    isInitializing,
    connectWallet,
    disconnectWallet,
  } = useContext(WalletContext); // Consume the wallet context

  const [customAmount, setCustomAmount] = useState('');
  const [selectedAsset, setSelectedAsset] = useState('ERMO');
  const [openPopup, setOpenPopup] = useState(false);
  const [popupDetails, setPopupDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [walletBalances, setWalletBalances] = useState({
    ERMO: 0,
    ADA: 0,
    jackpot: 0,
  });
  const [tickets, setTickets] = useState(0);
  const [endTime, setEndTime] = useState(null);
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const [ticketsSold, setTicketsSold] = useState({
    ERMO: 0,
    ADA: 0,
  });
  const [myTickets, setMyTickets] = useState({
    ERMO: null,
    ADA: null,
  }); // State to store user's tickets
  const [loadingMyTickets, setLoadingMyTickets] = useState(false); // Loading state for My Tickets
  const [openTicketDialog, setOpenTicketDialog] = useState(false); // State for ticket popup

  // New state to store total amounts from ticket transactions
  const [totalAmounts, setTotalAmounts] = useState({
    ERMO: 0,
    ADA: 0,
  });

  // Snackbar State
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'error', 'warning', 'info', 'success'
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleAssetChange = (event) => {
    setSelectedAsset(event.target.value);
    setCustomAmount('');
    setTickets(0);
  };

  // Define the ERMO Asset Identifier without the dot separator
  const ERMO_ASSET_IDENTIFIER =
    'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f'; // Concatenated PolicyID and AssetNameHex

  // Asset Ticket Mapping
  const assetTicketMapping = {
    ERMO: {
      identifier: ERMO_ASSET_IDENTIFIER,
      multiple: 100, // 100 ERMO = 1 ticket
    },
    ADA: {
      identifier: 'lovelace',
      multiple: 10, // 10 ADA = 1 ticket
    },
  };

  const handleCustomAmountChange = (event) => {
    const value = event.target.value;
    setCustomAmount(value);

    // Calculate tickets based on input amount and selected asset
    const amount = Number(value);
    const requiredMultiple = assetTicketMapping[selectedAsset].multiple;

    if (!isNaN(amount) && amount >= requiredMultiple) {
      setTickets(Math.floor(amount / requiredMultiple));
    } else {
      setTickets(0);
    }
  };

  const handleCopyPayout = () => {
    if (popupDetails.txHash) {
      navigator.clipboard.writeText(popupDetails.txHash).then(
        () => {
          setSnackbar({
            open: true,
            message: 'Transaction hash copied to clipboard!',
            severity: 'success',
          });
        },
        (err) => {
          console.error('Could not copy text: ', err);
          setSnackbar({
            open: true,
            message: 'Failed to copy hash.',
            severity: 'error',
          });
        }
      );
    }
  };

  const handleCreateTransaction = async () => {
    if (!isConnected) {
      setSnackbar({
        open: true,
        message: 'Please connect your wallet before creating a transaction.',
        severity: 'warning',
      });
      return;
    }

    if (!lucid) {
      setSnackbar({
        open: true,
        message: 'Lucid is not initialized. Please try again later.',
        severity: 'error',
      });
      return;
    }

    let amount = Number(customAmount);
    if (!selectedAsset) {
      setSnackbar({
        open: true,
        message: 'Please select an asset.',
        severity: 'warning',
      });
      return;
    }

    const requiredMultiple = assetTicketMapping[selectedAsset].multiple;

    if (!amount || isNaN(amount)) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid amount.',
        severity: 'warning',
      });
      return;
    }
    if (amount < requiredMultiple) {
      setSnackbar({
        open: true,
        message: `Amount must be at least ${requiredMultiple} ${selectedAsset}.`,
        severity: 'warning',
      });
      return;
    }
    if (amount % requiredMultiple !== 0) {
      const roundedAmount = Math.round(amount / requiredMultiple) * requiredMultiple;
      setSnackbar({
        open: true,
        message: `Amount must be a multiple of ${requiredMultiple} ${selectedAsset}. Rounding to nearest ${requiredMultiple}: ${roundedAmount} ${selectedAsset}.`,
        severity: 'info',
      });
      setCustomAmount(roundedAmount);
      setTickets(Math.floor(roundedAmount / requiredMultiple));
      return;
    }

    const numTickets = amount / assetTicketMapping[selectedAsset].multiple; // Calculate number of tickets

    try {
      setLoading(true);
      // Fetch recipientAddress from Lottery.json
      const response = await axios.get(`${process.env.PUBLIC_URL}/Lottery.json`);
      const lotteryData = response.data;
      const recipientAddress = lotteryData.recipientAddress;

      if (!recipientAddress) {
        throw new Error('Recipient address is missing in Lottery.json.');
      }

      let txBuilder = lucid.newTx();

      if (selectedAsset === 'ERMO') {
        txBuilder = txBuilder.payToAddress(recipientAddress, {
          [assetTicketMapping['ERMO'].identifier]: BigInt(amount),
        });
      } else if (selectedAsset === 'ADA') {
        txBuilder = txBuilder.payToAddress(recipientAddress, {
          lovelace: BigInt(amount * 1000000), // Convert ADA to Lovelace
        });
      }

      // Attach metadata directly based on asset type
      txBuilder = txBuilder.attachMetadata(1, {
        lotteryId: 'lottery123',
        tickets: numTickets,
        assetType: selectedAsset,
      });

      const tx = await txBuilder.complete();
      const signedTx = await tx.sign().complete();
      const txHash = await signedTx.submit();
      console.log('Transaction submitted:', txHash);

      // Wait for the transaction to be confirmed
      await lucid.awaitTx(txHash);

      setPopupDetails({
        txHash,
        amount: amount,
        tickets: numTickets,
        asset: selectedAsset,
      });
      setOpenPopup(true);
      setSnackbar({
        open: true,
        message: 'Transaction successful!',
        severity: 'success',
      });
      setLoading(false);

      // Refresh data after transaction
      fetchLotteryData(); // Fetch updated total tickets sold
      handleMyTickets(); // Fetch updated tickets for the user
    } catch (error) {
      console.error('Error creating transaction:', error.message);
      setSnackbar({
        open: true,
        message: `Error creating the transaction: ${error.message}`,
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const fetchLotteryData = async () => {
    try {
      const response = await axios.get(`${process.env.PUBLIC_URL}/Lottery.json`);
      const lotteryData = response.data;

      // Get recipientAddress
      const recipientAddress = lotteryData.recipientAddress;

      if (!recipientAddress) {
        throw new Error('Recipient address is missing in Lottery.json.');
      }

      if (!lucid) {
        setSnackbar({
          open: true,
          message: 'Lucid is not initialized. Please try again later.',
          severity: 'error',
        });
        return;
      }

      // Fetch total tickets sold and total amounts from ticket transactions
      const totalTicketsData = await fetchTotalTicketsSold(recipientAddress);
      setTicketsSold({
        ERMO: totalTicketsData.ERMO.tickets,
        ADA: totalTicketsData.ADA.tickets,
      });

      console.log(
        `Tickets Sold - ERMO: ${totalTicketsData.ERMO.tickets}, ADA: ${totalTicketsData.ADA.tickets}`
      );

      // Use total amounts from ticket transactions to calculate prizes
      setTotalAmounts({
        ERMO: totalTicketsData.ERMO.totalAmount,
        ADA: totalTicketsData.ADA.totalAmount,
      });

      if (lotteryData.endTime) {
        setEndTime(lotteryData.endTime); // Using endTime from Lottery.json
      } else {
        console.error('endTime is missing in Lottery.json');
      }
    } catch (error) {
      console.error('Error fetching Lottery.json:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch lottery data.',
        severity: 'error',
      });
    }
  };

  const fetchTotalTicketsSold = async (recipientAddress) => {
    try {
      // Replace with your Blockfrost API key (ensure it's securely stored)
      const blockfrostApiKey = process.env.REACT_APP_BLOCKFROST_API_KEY;

      if (!blockfrostApiKey) {
        throw new Error(
          'Blockfrost API key is missing. Please set it in your environment variables.'
        );
      }

      // Base URL for Blockfrost API
      const blockfrostBaseUrl = 'https://cardano-mainnet.blockfrost.io/api/v0';

      // Function to make authenticated requests to Blockfrost
      const blockfrostRequest = async (url, params = {}) => {
        return axios.get(url, {
          baseURL: blockfrostBaseUrl,
          headers: {
            project_id: blockfrostApiKey,
          },
          params,
        });
      };

      // Fetch transactions to the lottery address
      let page = 1;
      let txHashes = [];
      let fetchMore = true;

      // Handle pagination to fetch all transactions
      while (fetchMore) {
        const txPageResponse = await blockfrostRequest(
          `/addresses/${recipientAddress}/transactions`,
          {
            order: 'asc',
            page,
            count: 100,
          }
        );

        const txPage = txPageResponse.data;

        if (txPage.length > 0) {
          txHashes = txHashes.concat(txPage.map((tx) => tx.tx_hash));
          page++;
        } else {
          fetchMore = false;
        }
      }

      let ermoTickets = 0;
      let adaTickets = 0;
      let ermoTotalAmount = 0;
      let adaTotalAmount = 0;

      console.log(`Total transactions fetched for tickets: ${txHashes.length}`);

      for (const txHash of txHashes) {
        // Fetch transaction UTXOs to determine assets
        const utxosResponse = await blockfrostRequest(`/txs/${txHash}/utxos`);
        const utxos = utxosResponse.data;

        // Filter UTXOs sent to recipientAddress
        const sentUtxos = utxos.outputs.filter((utxo) => utxo.address === recipientAddress);

        if (sentUtxos.length === 0) {
          console.log(`No UTXOs sent to recipient in transaction: ${txHash}`);
          continue; // No relevant UTXOs in this transaction
        }

        let hasERMO = false;
        let ermoAmount = 0;
        let adaAmount = 0;

        sentUtxos.forEach((utxo) => {
          const amountArray = utxo.amount;
          if (amountArray) {
            amountArray.forEach((amountObj) => {
              const assetKey = amountObj.unit;
              const quantity = amountObj.quantity;
              console.log(`Processing assetKey: ${assetKey}, amount: ${quantity}`);

              if (assetKey === assetTicketMapping['ERMO'].identifier) {
                hasERMO = true;
                ermoAmount += Number(quantity);
              }
              if (assetKey === 'lovelace') {
                adaAmount += Number(quantity);
              }
            });
          }
        });

        if (hasERMO) {
          // Count as ERMO tickets
          const ticketsFromTx = Math.floor(ermoAmount / assetTicketMapping['ERMO'].multiple);
          ermoTickets += ticketsFromTx;
          ermoTotalAmount += ermoAmount;
          console.log(`ERMO Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
        } else {
          // Count as ADA tickets
          const adaAmountInADA = adaAmount / 1000000; // Convert Lovelace to ADA
          const ticketsFromTx = Math.floor(adaAmountInADA / assetTicketMapping['ADA'].multiple);
          adaTickets += ticketsFromTx;
          adaTotalAmount += adaAmountInADA;
          console.log(`ADA Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
        }
      }

      console.log(`Total Tickets Sold - ERMO: ${ermoTickets}, ADA: ${adaTickets}`);
      console.log(`Total Amounts - ERMO: ${ermoTotalAmount}, ADA: ${adaTotalAmount}`);

      return {
        ERMO: { tickets: ermoTickets, totalAmount: ermoTotalAmount },
        ADA: { tickets: adaTickets, totalAmount: adaTotalAmount },
      };
    } catch (error) {
      console.error('Error fetching total tickets sold:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (lucid) {
      fetchLotteryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lucid]);

  const handleMyTickets = async () => {
    if (!isConnected || !walletAddress) {
      setSnackbar({
        open: true,
        message: 'Please connect your wallet to view your tickets.',
        severity: 'warning',
      });
      return;
    }

    try {
      setLoadingMyTickets(true);

      const response = await axios.get(`${process.env.PUBLIC_URL}/Lottery.json`);
      const lotteryData = response.data;

      // Get recipientAddress
      const recipientAddress = lotteryData.recipientAddress;

      if (!recipientAddress) {
        throw new Error('Recipient address is missing in Lottery.json.');
      }

      // Replace with your Blockfrost API key (ensure it's securely stored)
      const blockfrostApiKey = process.env.REACT_APP_BLOCKFROST_API_KEY;

      if (!blockfrostApiKey) {
        throw new Error(
          'Blockfrost API key is missing. Please set it in your environment variables.'
        );
      }

      // Base URL for Blockfrost API
      const blockfrostBaseUrl = 'https://cardano-mainnet.blockfrost.io/api/v0';

      // Function to make authenticated requests to Blockfrost
      const blockfrostRequest = async (url, params = {}) => {
        return axios.get(url, {
          baseURL: blockfrostBaseUrl,
          headers: {
            project_id: blockfrostApiKey,
          },
          params,
        });
      };

      // Fetch transactions to the lottery address
      let page = 1;
      let txHashes = [];
      let fetchMore = true;

      // Handle pagination to fetch all transactions
      while (fetchMore) {
        const txPageResponse = await blockfrostRequest(
          `/addresses/${recipientAddress}/transactions`,
          {
            order: 'asc',
            page,
            count: 100,
          }
        );

        const txPage = txPageResponse.data;

        if (txPage.length > 0) {
          txHashes = txHashes.concat(txPage.map((tx) => tx.tx_hash));
          page++;
        } else {
          fetchMore = false;
        }
      }

      let ermoTickets = 0;
      let adaTickets = 0;

      console.log(`Total transactions fetched for user's tickets: ${txHashes.length}`);

      for (const txHash of txHashes) {
        // Fetch transaction UTXOs to determine assets
        const utxosResponse = await blockfrostRequest(`/txs/${txHash}/utxos`);
        const utxos = utxosResponse.data;

        // Check if the transaction is from the connected wallet
        const isFromWallet = utxos.inputs.some(
          (input) => input.address.toLowerCase() === walletAddress.toLowerCase()
        );

        if (isFromWallet) {
          // Filter UTXOs sent to recipientAddress
          const sentUtxos = utxos.outputs.filter(
            (utxo) => utxo.address.toLowerCase() === recipientAddress.toLowerCase()
          );

          if (sentUtxos.length === 0) {
            continue; // No relevant UTXOs in this transaction
          }

          let hasERMO = false;
          let ermoAmount = 0;
          let adaAmount = 0;

          sentUtxos.forEach((utxo) => {
            const amountArray = utxo.amount;
            if (amountArray) {
              amountArray.forEach((amountObj) => {
                const assetKey = amountObj.unit;
                const quantity = amountObj.quantity;
                console.log(`Processing assetKey: ${assetKey}, amount: ${quantity}`);

                if (assetKey === assetTicketMapping['ERMO'].identifier) {
                  hasERMO = true;
                  ermoAmount += Number(quantity);
                }
                if (assetKey === 'lovelace') {
                  adaAmount += Number(quantity);
                }
              });
            }
          });

          if (hasERMO) {
            // Count as ERMO tickets
            const ticketsFromTx = Math.floor(ermoAmount / assetTicketMapping['ERMO'].multiple);
            ermoTickets += ticketsFromTx;
            console.log(`User ERMO Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
          } else {
            // Count as ADA tickets
            const adaAmountInADA = adaAmount / 1000000; // Convert Lovelace to ADA
            const ticketsFromTx = Math.floor(adaAmountInADA / assetTicketMapping['ADA'].multiple);
            adaTickets += ticketsFromTx;
            console.log(`User ADA Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
          }
        }
      }

      console.log(`Total User Tickets - ERMO: ${ermoTickets}, ADA: ${adaTickets}`);

      setMyTickets({
        ERMO: ermoTickets,
        ADA: adaTickets,
      });
      setLoadingMyTickets(false);
      setOpenTicketDialog(true); // Open the ticket popup
    } catch (error) {
      console.error('Error fetching your tickets:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch your tickets.',
        severity: 'error',
      });
      setLoadingMyTickets(false);
    }
  };

  // Generate a mock serial number for the ticket
  const generateSerialNumber = () => {
    // Simple random serial number generator
    return 'SN-' + Math.floor(100000 + Math.random() * 900000);
  };

  // Calculate prize distributions using totalAmounts from ticket transactions
  const calculatePrizes = (asset) => {
    const balance = totalAmounts[asset] || 0;
    const totalPrizesPool = balance * 0.9; // 90% of balance

    const firstPrize = totalPrizesPool * 0.5; // 50% of 90%
    const secondPrize = totalPrizesPool * 0.3; // 30% of 90%
    const thirdPrize = totalPrizesPool * 0.2; // 20% of 90%

    const burn = balance * 0.05; // 5% of balance for both ERMO and ADA
    const jackpot = balance * 0.05 + (walletBalances.jackpot || 0); // 5% of balance + jackpot from Lottery.json

    return {
      firstPrize: firstPrize || 0,
      secondPrize: secondPrize || 0,
      thirdPrize: thirdPrize || 0,
      burn: burn || 0, // Include burn for both ERMO and ADA
      jackpot: jackpot || 0,
    };
  };

  const prizes = {
    ERMO: calculatePrizes('ERMO'),
    ADA: calculatePrizes('ADA'),
  };

  const handleCloseTicketDialog = () => {
    setOpenTicketDialog(false);
    setMyTickets({ ERMO: null, ADA: null }); // Reset tickets
  };

  return (
    <Container>
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <MainGrid container spacing={4}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          {/* Timer */}
          {endTime && <Timer endTime={endTime} onCountdownEnd={() => setIsCountdownOver(true)} />}

          {/* Tickets Sold */}
          <StyledCard sx={{ marginTop: 2 }}>
            <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
              Tickets Sold
            </Typography>
            <Grid container spacing={3}>
              {/* ERMO Tickets Sold */}
              <Grid item xs={12} sm={6}>
                <InfoCard asset="ERMO" ticketsSold={ticketsSold.ERMO} />
              </Grid>
              {/* ADA Tickets Sold */}
              <Grid item xs={12} sm={6}>
                <InfoCard asset="ADA" ticketsSold={ticketsSold.ADA} />
              </Grid>
            </Grid>
            {/* "My Tickets" Button */}
            <Box display="flex" justifyContent="center" marginTop={2}>
              <SmallButton onClick={handleMyTickets} disabled={loadingMyTickets}>
                {loadingMyTickets ? 'Loading...' : 'My Tickets'}
              </SmallButton>
            </Box>
          </StyledCard>

          {/* Prize Distribution */}
          <Box sx={{ marginTop: 2 }}>
            <PrizeDistribution prizes={prizes} />
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          {/* Lottery Card */}
          <StyledCard>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar
                alt="Lottery"
                src={selectedAsset === 'ERMO' ? '/ermo.png' : '/ada.png'} // Dynamic image based on selected asset
                sx={{
                  width: 80,
                  height: 80,
                  marginBottom: 2,
                }}
              />
              <HeaderTypography variant="h6">Lottery - {selectedAsset}</HeaderTypography>
            </Box>

            <Typography
              variant="body1"
              sx={{
                fontSize: '1rem',
                marginTop: 2,
              }}
            >
              Participate in the lottery by betting {selectedAsset}. Each asset has its own ticket rate.
            </Typography>

            {/* Asset Selection */}
            <Box marginTop={3}>
              <StyledFormControl variant="outlined">
                <Select
                  value={selectedAsset}
                  onChange={handleAssetChange}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      return value;
                    }
                    return 'Select Asset';
                  }}
                >
                  <MenuItem value="ERMO">ERMO</MenuItem>
                  <MenuItem value="ADA">ADA</MenuItem>
                  {/* Add other assets here when available */}
                </Select>
              </StyledFormControl>

              <Typography variant="body2" sx={{ marginTop: 2 }}>
                Enter the amount (multiples of {assetTicketMapping[selectedAsset].multiple}{' '}
                {selectedAsset}):
              </Typography>

              <StyledTextField
                variant="outlined"
                type="number"
                value={customAmount}
                onChange={handleCustomAmountChange}
                placeholder={`Enter amount (multiple of ${assetTicketMapping[selectedAsset].multiple} ${selectedAsset})`}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{selectedAsset}</InputAdornment>,
                }}
              />

              {/* Display number of tickets */}
              {tickets > 0 && (
                <Typography variant="body2" sx={{ marginTop: 2, fontWeight: 'bold' }}>
                  You will get {tickets} ticket{tickets > 1 ? 's' : ''}.
                </Typography>
              )}
            </Box>

            {/* Create Transaction Button */}
            <Box marginTop={4}>
              <StyledButton
                onClick={handleCreateTransaction}
                disabled={loading || isCountdownOver || !isConnected}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
              >
                {loading ? 'Creating Transaction...' : 'Create Transaction'}
              </StyledButton>
              {!isConnected && (
                <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
                  Please connect your wallet to participate.
                </Typography>
              )}
            </Box>

            {/* Lottery Mechanics Description */}
            <Box textAlign="left" marginTop={4}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                How It Works:
              </Typography>
              {[
                `Every ${assetTicketMapping[selectedAsset].multiple} ${selectedAsset} equals 1 ticket.`,
                'There are 3 winners.',
                '5% is burned, 5% goes to the jackpot.',
                'First winner receives 50% of the prize pool.',
                'Second winner receives 30% of the prize pool.',
                'Third winner receives 20% of the prize pool.',
                'There is a 1/100 chance to win the jackpot.',
                'If the jackpot is not won, it accumulates for the next week.',
              ].map((item, index) => (
                <Typography variant="body2" sx={{ marginTop: 1 }} key={index}>
                  - {item}
                </Typography>
              ))}
            </Box>

            {/* Popup Dialog for Transaction Success */}
            <Dialog open={openPopup} onClose={handleClosePopup} fullWidth maxWidth="sm">
              <DialogTitle>Transaction Successful!</DialogTitle>
              <DialogContent>
                <Box id="popup-details">
                  <Typography variant="body1" sx={{ marginBottom: 1, wordBreak: 'break-word' }}>
                    <strong>Transaction Hash:</strong> {popupDetails.txHash ? popupDetails.txHash : 'N/A'}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Asset:</strong> {popupDetails.asset ? popupDetails.asset : 'N/A'}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Amount:</strong>{' '}
                    {popupDetails.amount !== undefined ? popupDetails.amount.toLocaleString() : '0'}{' '}
                    {popupDetails.asset}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Tickets:</strong>{' '}
                    {popupDetails.tickets !== undefined ? popupDetails.tickets.toLocaleString() : '0'}{' '}
                    Ticket{popupDetails.tickets > 1 ? 's' : ''}
                  </Typography>
                  <Box display="flex" justifyContent="center" marginTop={2}>
                    <Avatar
                      alt="Lottery"
                      src={popupDetails.asset === 'ERMO' ? '/ermo.png' : '/ada.png'} // Use asset-specific images
                      sx={{
                        width: 80,
                        height: 80,
                      }}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StyledButton
                      onClick={handleCopyPayout}
                      startIcon={<ContentCopyIcon />}
                      fullWidth
                    >
                      Copy Hash
                    </StyledButton>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton onClick={handleClosePopup} startIcon={<CloseIcon />} fullWidth>
                      Close
                    </StyledButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </StyledCard>
        </Grid>
      </MainGrid>

      {/* Ticket Popup Dialog */}
      <Dialog open={openTicketDialog} onClose={handleCloseTicketDialog} fullWidth maxWidth="sm">
        <DialogTitle>Your Lottery Tickets</DialogTitle>
        <DialogContent>
          {myTickets.ERMO !== null && myTickets.ADA !== null && (
            <>
              {myTickets.ERMO + myTickets.ADA > 0 ? (
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                  {Array(myTickets.ERMO)
                    .fill('ERMO')
                    .concat(Array(myTickets.ADA).fill('ADA'))
                    .slice(0, 10)
                    .map((asset, index) => (
                      <TicketCard key={index}>
                        <Avatar
                          alt={asset}
                          src={asset === 'ERMO' ? '/ermo.png' : '/ada.png'}
                          sx={{ width: 60, height: 60, margin: '0 auto 10px' }}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {asset} Ticket
                        </Typography>
                        <Typography variant="body2">Serial: {generateSerialNumber()}</Typography>
                      </TicketCard>
                    ))}
                </Box>
              ) : (
                <Typography
                  variant="body1"
                  sx={{ marginTop: 2, textAlign: 'center' }}
                >
                  You have no tickets.
                </Typography>
              )}
              {myTickets.ERMO + myTickets.ADA > 10 && (
                <Typography
                  variant="body2"
                  sx={{ marginTop: 1, textAlign: 'center' }}
                >
                  Showing first 10 tickets out of {myTickets.ERMO + myTickets.ADA}
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseTicketDialog} startIcon={<CloseIcon />} fullWidth>
            Close
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

// InfoCard Component
const InfoCard = ({ asset, ticketsSold }) => (
  <Box
    sx={{
      padding: 2,
      textAlign: 'center',
    }}
  >
    <Avatar
      alt={asset}
      src={asset === 'ERMO' ? '/ermo.png' : '/ada.png'}
      sx={{ width: 60, height: 60, margin: '0 auto 10px' }}
    />
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
      {asset}
    </Typography>
    <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: 1 }}>
      {ticketsSold !== undefined ? ticketsSold.toLocaleString() : '0'}
    </Typography>
    <Typography variant="body1">Tickets Sold</Typography>
  </Box>
);

export default Lottery;
