import React from 'react';
import { Grid, Typography, Box, useMediaQuery, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

// ---------------------- Styled Components ----------------------

// Main container without background image or gradient
const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 2),
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 1),
  },
}));

// Features section
const FeaturesSection = styled(Grid)(({ theme }) => ({
  maxWidth: '1200px',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const FeatureCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '15px',
  boxShadow: theme.shadows[4],
  padding: theme.spacing(4),
  textAlign: 'center',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[6],
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const FeatureIcon = styled('img')(({ theme }) => ({
  width: '80px',
  height: '80px',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '60px',
    height: '60px',
  },
}));

const FeatureName = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 600,
  fontSize: '1.5rem',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.3rem',
  },
}));

const FeatureDescription = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 400,
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

const FeatureButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  textTransform: 'none',
  padding: theme.spacing(1.5, 3),
  fontSize: '1rem',
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

// ---------------------- Home Component ----------------------

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const features = [
    {
      name: 'Bet',
      icon: '/DICE.svg',
      description: 'Place your bets and win big!',
      path: '/bet',
    },
    {
      name: 'Lottery',
      icon: '/CUP.svg',
      description: 'Participate in exciting lotteries.',
      path: '/lottery',
    },
  ];

  return (
    <MainContainer>
      <FeaturesSection container spacing={4} justifyContent="center" alignItems="stretch">
        {features.map((feature) => (
          <Grid item xs={12} sm={6} md={4} key={feature.name}>
            <FeatureCard>
              <FeatureIcon src={feature.icon} alt={`${feature.name} Icon`} />
              <FeatureName>{feature.name}</FeatureName>
              <FeatureDescription>{feature.description}</FeatureDescription>
              <FeatureButton component={Link} to={feature.path}>
                Explore {feature.name}
              </FeatureButton>
            </FeatureCard>
          </Grid>
        ))}
      </FeaturesSection>
    </MainContainer>
  );
};

export default React.memo(Home);
