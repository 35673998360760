// src/components/Navbar.js

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import WalletConnect from './WalletConnect'; // Ensure the path is correct

// ---------------------- Styled Components ----------------------

// Styled AppBar with solid background and no shadow
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main, // Solid background color
  borderBottom: `3px solid ${theme.palette.common.black}`,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 1),
  },
}));

// Logo Typography with custom font and animated color
const LogoTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Schoolbell', cursive",
  fontSize: '2.2rem',
  color: theme.palette.common.white,
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
  cursor: 'pointer',
  textDecoration: 'none',
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.6rem',
  },
}));

// Styled Button for navigation
const NavButton = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.secondary.dark : theme.palette.secondary.main,
  color: active ? theme.palette.common.white : theme.palette.common.black,
  fontFamily: "'Schoolbell', cursive",
  border: `2px solid ${theme.palette.common.black}`,
  boxShadow: theme.shadows[2],
  minWidth: '100px',
  padding: theme.spacing(0.75, 1.5),
  fontSize: '1rem',
  marginLeft: theme.spacing(1),
  transition: 'transform 0.2s, background-color 0.3s, color 0.3s',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '80px',
    padding: theme.spacing(0.5, 1),
    fontSize: '0.8rem',
    marginLeft: theme.spacing(0.5),
  },
}));

// Styled Drawer List for Mobile Menu with cartoony black border
const DrawerList = styled(List)(({ theme }) => ({
  width: 250,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderLeft: '5px solid #000', // Cartoony black border on the left
  height: '100%', // Ensure the border goes all the way down
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

// Navigation Items with Labels, Paths, and Icons
const navItems = [
  { label: 'Bet', path: '/bet', icon: '/DICE.svg' },
  { label: 'Lottery', path: '/lottery', icon: '/CUP.svg' },
];

// Navbar Component
const Navbar = () => {
  const theme = useTheme();
  const location = useLocation(); // Hook to get current path
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Toggle mobile menu drawer
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <StyledAppBar>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* Left: Wallet Connection */}
          <Box display="flex" alignItems="center">
            <WalletConnect />
          </Box>

          {/* Center: Logo - Links to Home */}
          <LogoTypography component={Link} to="/" variant="h6">
            ErmoApp
          </LogoTypography>

          {/* Right: Navigation Buttons */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: theme.spacing(1) }}>
            {navItems.map((item) => (
              <NavButton
                key={item.label}
                component={Link}
                to={item.path}
                active={location.pathname === item.path ? 1 : 0}
                startIcon={
                  <Box
                    component="img"
                    src={item.icon}
                    alt={`${item.label} Icon`}
                    sx={{ width: '20px', height: '20px' }}
                  />
                }
                aria-label={item.label}
              >
                {item.label}
              </NavButton>
            ))}
          </Box>

          {/* Mobile Menu Icon */}
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </StyledAppBar>

      {/* Mobile Drawer Menu */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={toggleMobileMenu}
        sx={{ display: { xs: 'block', sm: 'none' } }}
      >
        <DrawerList>
          {navItems.map((item) => (
            <ListItem
              button
              component={Link}
              to={item.path}
              key={item.label}
              onClick={toggleMobileMenu}
              selected={location.pathname === item.path}
              sx={{
                borderRadius: '8px',
                marginBottom: theme.spacing(2),
                backgroundColor:
                  location.pathname === item.path ? '#FFEDED' : 'transparent',
                border: '2px solid #000', // Cartoony black border for each item
                '&:hover': {
                  backgroundColor: '#FFCCCC',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <Box
                  component="img"
                  src={item.icon}
                  alt={`${item.label} Icon`}
                  sx={{
                    width: '24px',
                    height: '24px',
                    filter:
                      location.pathname === item.path
                        ? 'invert(0%)'
                        : 'invert(50%)',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  fontFamily: "'Schoolbell', cursive",
                  fontSize: '1.2rem',
                  color: location.pathname === item.path ? '#FF6347' : '#000',
                }}
              />
            </ListItem>
          ))}
        </DrawerList>
      </Drawer>
    </>
  );
};

export default Navbar;
