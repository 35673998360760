// src/components/WalletConnect.js

import React, { useContext } from 'react';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { FaWallet } from 'react-icons/fa'; // Importing FaWallet from react-icons/fa
import { WalletContext } from '../WalletProvider'; // Import WalletContext

// Styled Button using MUI's styled API
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#f87171', // Red color
  color: 'white',
  border: '1px solid #d92626', // Darker red border
  boxShadow: theme.shadows[1],
  padding: '6px 12px', // Reduced padding for smaller size
  borderRadius: '4px', // Slightly rounded corners for rectangular shape
  fontSize: '14px', // Smaller font size
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 'auto', // Adjust to content size
  '&:hover': {
    backgroundColor: '#ef4444', // Darker red on hover
    borderColor: '#b91c1c', // Even darker red border on hover
  },
  '&:disabled': {
    backgroundColor: '#fecaca', // Lighter red when disabled
    borderColor: '#fca5a5', // Lighter red border when disabled
    cursor: 'not-allowed',
  },
}));

const WalletConnect = () => {
  const { isConnected, walletAddress, isInitializing, connectWallet, disconnectWallet } = useContext(WalletContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // List of supported wallets with their respective window.cardano keys and icons
  const supportedWallets = {
    nami: { name: 'Nami', icon: <FaWallet /> },
    eternl: { name: 'Eternl', icon: <FaWallet /> },
    flint: { name: 'Flint', icon: <FaWallet /> },
    yoroi: { name: 'Yoroi', icon: <FaWallet /> },
    gerowallet: { name: 'GeroWallet', icon: <FaWallet /> },
    // Add more wallets here if needed
  };

  const handleWalletMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleWalletSelect = async (walletKey) => {
    setAnchorEl(null);
    await connectWallet(walletKey);
  };

  // Function to truncate wallet address for better readability
  const truncateAddress = (address) => {
    if (!address) return '';
    const start = address.substring(0, 6);
    const end = address.substring(address.length - 4);
    return `${start}...${end}`;
  };

  // Function to check if the selected wallet is installed
  const isWalletInstalled = (walletKey) => {
    return window.cardano && window.cardano[walletKey];
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
      {isConnected ? (
        <Tooltip title="Disconnect Wallet" arrow>
          <StyledButton variant="contained" onClick={disconnectWallet}>
            <FaWallet style={{ fontSize: '16px' }} />
            <span style={{ marginLeft: '8px' }}>{truncateAddress(walletAddress)}</span>
          </StyledButton>
        </Tooltip>
      ) : (
        <>
          <Tooltip title={isInitializing ? 'Initializing...' : 'Connect Wallet'} arrow>
            <StyledButton
              variant="contained"
              onClick={handleWalletMenuClick}
              disabled={isInitializing || !Object.keys(supportedWallets).some(key => isWalletInstalled(key))}
            >
              <FaWallet style={{ fontSize: '16px' }} />
            </StyledButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5, // Adjust as needed
                width: '20ch',
              },
            }}
          >
            {Object.keys(supportedWallets).map((key) => (
              <MenuItem
                key={key}
                onClick={() => handleWalletSelect(key)}
                disabled={!isWalletInstalled(key)}
              >
                <span style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                  {supportedWallets[key].icon}
                </span>
                {supportedWallets[key].name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};

export default WalletConnect;
