/* Layout.js */

import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Box, Toolbar } from '@mui/material'; // Import Toolbar from MUI
import styled from 'styled-components';

// Styled container for the layout with a background image
const LayoutContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the layout takes the full viewport height */
  background-image: url('/background.png'); /* Path to your background image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Keeps background in place while scrolling */
`;

// Styled container for the main content
const MainContent = styled(Box)`
  flex-grow: 1; /* Ensures content takes up the remaining space between navbar and footer */
  padding: 40px 20px; /* Adjust as needed */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  backdrop-filter: blur(5px); /* Optional: Adds a blur effect to the background */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap; /* Allows content to wrap on smaller screens */

  @media (max-width: 600px) {
    padding: 30px 10px; /* Adjusted padding for smaller screens */
  }
`;

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <Navbar />
      <Toolbar /> {/* Spacer to offset fixed Navbar */}
      <MainContent>
        {children}
      </MainContent>
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
