import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip, CircularProgress } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import DownloadIcon from '@mui/icons-material/Download'; // **Added Import**
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';

/**
 * Utility function to get the current timestamp in a safe format for filenames
 * @returns {string} - Formatted timestamp as 'yyyy-mm-dd_hh-mm-ss'
 */
const getTimestamp = () => {
  const now = new Date();
  const date = now.toISOString().split('T')[0]; // Format date as yyyy-mm-dd
  const time = now.toTimeString().split(' ')[0].replace(/:/g, '-'); // Format time as hh-mm-ss
  return `${date}_${time}`;
};

/**
 * DownloadButton3 Component
 * Allows users to download a chart as a PNG image with enhanced layout.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.chartRef - Ref to the chart element to capture.
 */
const DownloadButton3 = ({ chartRef }) => {
  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor
  const openMenu = Boolean(anchorEl); // Determines if the menu is open
  const [loading, setLoading] = useState(false); // Loading state for export

  /**
   * Opens the download options menu.
   * @param {Object} event - Click event.
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the download options menu.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Exports the chart as a PNG image with the ERMO logo added.
   * Enhancements:
   * - Adds more space between assets.
   * - Clearly separates different categories.
   * - Ensures the ERMO image is loaded before drawing.
   */
  const exportPNG = async () => {
    if (!chartRef || !chartRef.current) {
      alert('No chart to export.');
      return;
    }

    setLoading(true); // Start loading

    try {
      const chartElement = chartRef.current;

      // Capture the chart as a canvas using html2canvas
      const canvas = await html2canvas(chartElement, {
        scale: 2, // Increase scale for better resolution
        useCORS: true, // Enable CORS to load external images
        allowTaint: true, // Allow cross-origin images
      });

      const ctx = canvas.getContext('2d');

      // Load the ERMO image
      const ermoImage = new Image();
      ermoImage.src = '/ermo.jpg'; // Ensure this path is correct and the image exists in the public directory

      // Wait for the ERMO image to load
      await new Promise((resolve, reject) => {
        ermoImage.onload = resolve;
        ermoImage.onerror = reject;
      });

      // Define position and size for the ERMO image
      const ermoWidth = 60; // Width of the ERMO image in the PNG
      const ermoHeight = 60; // Height of the ERMO image in the PNG
      const padding = 20; // Padding from the edges

      // Draw the ERMO image on the canvas at the bottom right corner with padding
      ctx.drawImage(
        ermoImage,
        canvas.width - ermoWidth - padding,
        canvas.height - ermoHeight - padding,
        ermoWidth,
        ermoHeight
      );

      // Convert the canvas to a PNG image
      const imgData = canvas.toDataURL('image/png');

      // Create a temporary link to trigger the download with timestamp in filename
      const link = document.createElement('a');
      link.href = imgData;
      link.setAttribute('download', `chart_${getTimestamp()}.png`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting chart as PNG:', error);
      alert('Failed to export chart as PNG.');
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div>
      {/* Download Button with Tooltip */}
      <Tooltip title="Download Chart">
        <IconButton
          onClick={handleClick}
          style={{
            backgroundColor: '#FF4500',
            color: '#FFFFFF',
            fontFamily: "'Schoolbell', cursive",
            boxShadow: '1px 1px 0px #000',
            '&:hover': {
              backgroundColor: '#e03e00',
              boxShadow: '2px 2px 0px #000',
            },
            transition: 'background-color 0.3s, box-shadow 0.3s',
          }}
          aria-label="download chart"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : <GetAppIcon />}
        </IconButton>
      </Tooltip>

      {/* Download Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'download-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            exportPNG();
          }}
        >
          <DownloadIcon fontSize="small" style={{ marginRight: '8px' }} /> {/* Now Defined */}
          Download as PNG
        </MenuItem>
        {/* Future download options (e.g., PDF) can be added here */}
      </Menu>
    </div>
  );
};

// PropTypes for type checking
DownloadButton3.propTypes = {
  chartRef: PropTypes.object.isRequired, // Expecting a React ref object
};

export default DownloadButton3;
