// src/DownloadButton.js

import React from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';

const DownloadButton = ({ transactions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to export transactions as CSV
  const exportCSV = () => {
    if (!transactions || transactions.length === 0) {
      alert('No transactions to export.');
      return;
    }

    // Convert transactions to worksheet
    const worksheet = XLSX.utils.json_to_sheet(transactions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

    // Generate CSV and trigger download
    XLSX.writeFile(workbook, 'transactions.csv');
  };

  // Function to export transactions as XLSX
  const exportXLSX = () => {
    if (!transactions || transactions.length === 0) {
      alert('No transactions to export.');
      return;
    }

    // Convert transactions to worksheet
    const worksheet = XLSX.utils.json_to_sheet(transactions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

    // Generate XLSX and trigger download
    XLSX.writeFile(workbook, 'transactions.xlsx');
  };

  // Function to export transactions as PNG
  const exportPNG = () => {
    if (!transactions || transactions.length === 0) {
      alert('No transactions to export.');
      return;
    }

    // Create a temporary hidden container for all transactions
    const tempContainer = document.createElement('div');
    tempContainer.style.position = 'absolute';
    tempContainer.style.top = '-10000px';
    tempContainer.style.left = '-10000px';
    tempContainer.style.width = '1000px'; // Adjust width as needed
    tempContainer.style.backgroundColor = '#FFD700';
    tempContainer.style.border = '2px solid black';
    tempContainer.style.borderRadius = '10px';
    tempContainer.style.boxShadow = '1px 1px 0px #000';
    tempContainer.style.fontFamily = "'Schoolbell', cursive";
    tempContainer.style.padding = '10px';

    // Create a title
    const title = document.createElement('h2');
    title.innerText = 'Transaction History';
    title.style.textAlign = 'center';
    tempContainer.appendChild(title);

    // Create a table to hold transactions
    const table = document.createElement('table');
    table.style.width = '100%';
    table.style.borderCollapse = 'collapse';

    // Create table header
    const thead = document.createElement('thead');
    const headerRow = document.createElement('tr');

    const headers = ['Transaction Hash', 'Assets', 'Time', 'Bet', 'Result', 'Bet Status'];
    headers.forEach((header) => {
      const th = document.createElement('th');
      th.innerText = header;
      th.style.border = '1px solid black';
      th.style.padding = '8px';
      th.style.backgroundColor = '#FF4500';
      th.style.color = '#FFFFFF';
      headerRow.appendChild(th);
    });
    thead.appendChild(headerRow);
    table.appendChild(thead);

    // Create table body
    const tbody = document.createElement('tbody');

    transactions.forEach((tx) => {
      const row = document.createElement('tr');

      // Transaction Hash
      const hashCell = document.createElement('td');
      hashCell.innerText = tx.hash;
      hashCell.style.border = '1px solid black';
      hashCell.style.padding = '8px';
      hashCell.style.wordBreak = 'break-all';
      row.appendChild(hashCell);

      // Assets
      const assetsCell = document.createElement('td');
      assetsCell.innerHTML = tx.assets.map(asset => `${asset.amount} ${asset.asset}`).join('<br/>');
      assetsCell.style.border = '1px solid black';
      assetsCell.style.padding = '8px';
      row.appendChild(assetsCell);

      // Time
      const timeCell = document.createElement('td');
      timeCell.innerText = tx.time;
      timeCell.style.border = '1px solid black';
      timeCell.style.padding = '8px';
      row.appendChild(timeCell);

      // Bet
      const betCell = document.createElement('td');
      betCell.innerText = tx.betTitle;
      betCell.style.border = '1px solid black';
      betCell.style.padding = '8px';
      row.appendChild(betCell);

      // Result
      const resultCell = document.createElement('td');
      resultCell.innerText = tx.result || 'No result';
      resultCell.style.border = '1px solid black';
      resultCell.style.padding = '8px';
      row.appendChild(resultCell);

      // Bet Status
      const statusCell = document.createElement('td');
      statusCell.innerText = tx.betStatus;
      statusCell.style.border = '1px solid black';
      statusCell.style.padding = '8px';
      row.appendChild(statusCell);

      tbody.appendChild(row);
    });

    table.appendChild(tbody);
    tempContainer.appendChild(table);

    // Append the temporary container to the body
    document.body.appendChild(tempContainer);

    // Use html2canvas to capture the temporary container
    html2canvas(tempContainer, { scale: 2 }).then((canvas) => {
      // Convert the canvas to a data URL
      const imgData = canvas.toDataURL('image/png');

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      link.href = imgData;
      link.setAttribute('download', 'transactions.png');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Remove the temporary container
      document.body.removeChild(tempContainer);
    }).catch((error) => {
      console.error('Error exporting as PNG:', error);
      alert('Failed to export transactions as PNG.');
      // Remove the temporary container in case of error
      document.body.removeChild(tempContainer);
    });
  };

  return (
    <div>
      <Tooltip title="Download Transactions">
        <IconButton
          onClick={handleClick}
          style={{
            backgroundColor: '#FF4500',
            color: '#FFFFFF',
            fontFamily: "'Schoolbell', cursive",
            boxShadow: '1px 1px 0px #000',
          }}
          aria-label="download transactions"
        >
          <GetAppIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'download-button',
        }}
      >
        <MenuItem onClick={() => { handleClose(); exportCSV(); }}>CSV</MenuItem>
        <MenuItem onClick={() => { handleClose(); exportXLSX(); }}>XLSX</MenuItem>
        <MenuItem onClick={() => { handleClose(); exportPNG(); }}>PNG</MenuItem>
      </Menu>
    </div>
  );
};

export default DownloadButton;
