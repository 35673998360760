/* global BigInt */

import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Box,
  InputAdornment,
  CircularProgress,
  Grid,
  Tooltip,
  IconButton,
  Paper,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import html2canvas from 'html2canvas';
import DownloadButton2 from './DownloadButton2';
import DownloadButton3 from './DownloadButton3';
import MultiplierExplanationDialog from './explanations';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  ChartTooltip,
  Legend
);

// Styled Components

const BetContainer = styled(Paper)(({ theme }) => ({
  padding: '20px',
  borderRadius: '12px',
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  margin: '15px 0',
  fontFamily: "'Montserrat', sans-serif",
  [theme.breakpoints.down('sm')]: {
    padding: '15px',
  },
}));

const BetHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  color: theme.palette.text.primary,
  fontSize: '1.6rem',
  fontWeight: 600,
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',
  },
}));

const BestMultiplierTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  color: theme.palette.secondary.main,
  fontWeight: 500,
  fontSize: '1.1rem',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  color: theme.palette.text.secondary,
  fontSize: '1rem',
  margin: '20px 0',
  lineHeight: '1.6',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

const TimerMultiplierSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginBottom: '20px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const MultiplierContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.light,
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: '10px',
  padding: '15px',
  boxShadow: theme.shadows[3],
  width: '100%',
  maxWidth: '400px',
  height: '400px', // Set a fixed height
  flex: '1 1 400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transition: 'background 0.3s, border 0.3s',
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
    maxWidth: '300px',
    height: '300px', // Adjust height for mobile
    flex: '1 1 300px',
  },
}));

const MultiplierValue = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#000000',
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  },
}));

const MultiplierLabel = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '1.2rem',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const TimerContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.text.primary}`,
  borderRadius: '8px',
  padding: '15px 20px',
  boxShadow: theme.shadows[3],
  width: '100%',
  maxWidth: '400px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '10px 15px',
    maxWidth: '300px',
  },
}));

const TimerSegment = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const TimeValue = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '1.8rem',
  fontWeight: 600,
  color: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}));

const TimeLabel = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  justifyContent: 'flex-start',
  flexWrap: 'nowrap',
  marginTop: '20px',
  width: '100%', // Ensure container takes full width
  [theme.breakpoints.down('sm')]: {
    gap: '8px',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.down('xs')]: {
    gap: '5px',
    flexWrap: 'nowrap', // Keep buttons on the same row
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  fontFamily: "'Montserrat', sans-serif",
  height: '100%',
  boxSizing: 'border-box',
  '& .MuiSelect-select': {
    padding: '12px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.text.primary}`,
    boxShadow: theme.shadows[1],
    wordBreak: 'break-word',
    height: '100%',
    fontSize: '0.95rem',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiSelect-select': {
      padding: '10px',
      fontSize: '0.85rem',
    },
  },
}));

const StyledNumberField = styled(TextField)(({ theme }) => ({
  width: '100%',
  fontFamily: "'Montserrat', sans-serif",
  marginTop: '0px',
  height: '100%',
  boxSizing: 'border-box',
  '& .MuiInputBase-input': {
    fontFamily: "'Montserrat', sans-serif",
    padding: '12px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.text.primary}`,
    boxShadow: theme.shadows[1],
    height: '100%',
    fontSize: '0.95rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputAdornment-root': {
    fontFamily: "'Montserrat', sans-serif",
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiInputBase-input': {
      padding: '10px',
      fontSize: '0.85rem',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: '#FFFFFF',
  border: `1px solid ${theme.palette.secondary.dark}`,
  fontFamily: "'Montserrat', sans-serif",
  padding: '10px 20px',
  minWidth: '80px', // Reduced minWidth
  width: 'auto',
  flex: '1 1 auto', // Allow buttons to shrink and grow
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    color: '#fff',
  },
  boxShadow: theme.shadows[2],
  borderRadius: '8px',
  fontSize: '1rem', // Reduced font size
  textTransform: 'none',
  transition: 'background-color 0.3s, color 0.3s',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    padding: '6px 8px',
    fontSize: '0.8rem',
    flex: '1 1 0',
  },
  [theme.breakpoints.down('xs')]: {
    padding: '4px 6px',
    fontSize: '0.7rem',
    minWidth: '60px', // Further reduce minWidth
  },
}));

const PopupDialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.text.primary}`,
    boxShadow: theme.shadows[5],
    padding: '25px',
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: '15px',
    position: 'relative',
    width: '95%',
    maxWidth: '650px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  '& .MuiDialogTitle-root': {
    fontSize: '2.2rem',
    color: theme.palette.text.primary,
    textShadow: 'none',
    textAlign: 'center',
    fontWeight: 600,
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
    },
  },
  '& .MuiDialogContent-root': {
    color: theme.palette.text.primary,
    fontFamily: "'Montserrat', sans-serif",
    textAlign: 'left',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '15px',
  },
}));

const MultiplierChangeDialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.secondary.main}`,
    boxShadow: theme.shadows[5],
    padding: '30px',
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: '15px',
    maxHeight: '90vh',
    overflowY: 'auto',
    width: '95%',
    maxWidth: '650px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      padding: '25px',
    },
  },
  '& .MuiDialogTitle-root': {
    fontSize: '2rem',
    color: theme.palette.secondary.main,
    textShadow: 'none',
    textAlign: 'center',
    fontWeight: 600,
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  '& .MuiDialogContent-root': {
    color: theme.palette.text.primary,
    fontFamily: "'Montserrat', sans-serif",
    textAlign: 'left',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '15px',
  },
}));

const AvatarWithBorder = styled(Avatar)(({ theme }) => ({
  border: `2px solid ${theme.palette.text.primary}`,
  width: 80,
  height: 80,
  marginRight: '15px',
  [theme.breakpoints.down('sm')]: {
    width: 60,
    height: 60,
  },
}));

const AssetIcon = styled('img')(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '50%',
  border: `1px solid ${theme.palette.text.primary}`,
  objectFit: 'cover',
  marginRight: '8px',
  [theme.breakpoints.down('sm')]: {
    width: 35,
    height: 35,
  },
}));

const BoxedAsset = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  border: `1px solid ${theme.palette.text.primary}`,
  borderRadius: '8px',
  boxShadow: theme.shadows[1],
  margin: '8px 0',
  backgroundColor: theme.palette.background.default,
}));

// Timer Component
const Timer = ({ endTime }) => {
  const getTimeRemaining = (endTime) => {
    const total = Math.max(0, endTime - Date.now());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    const days = Math.floor(total / 1000 / 60 / 60 / 24);
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(getTimeRemaining(endTime));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(getTimeRemaining(endTime));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  return (
    <TimerContainer>
      <TimerSegment>
        <TimeValue>{formatNumber(timeLeft.days)}</TimeValue>
        <TimeLabel>Days</TimeLabel>
      </TimerSegment>
      <TimerSegment>
        <TimeValue>{formatNumber(timeLeft.hours)}</TimeValue>
        <TimeLabel>Hours</TimeLabel>
      </TimerSegment>
      <TimerSegment>
        <TimeValue>{formatNumber(timeLeft.minutes)}</TimeValue>
        <TimeLabel>Minutes</TimeLabel>
      </TimerSegment>
      <TimerSegment>
        <TimeValue>{formatNumber(timeLeft.seconds)}</TimeValue>
        <TimeLabel>Seconds</TimeLabel>
      </TimerSegment>
    </TimerContainer>
  );
};

// Utility Function
const formatNumber = (num) => {
  if (typeof num !== 'number') return num;
  return num.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
};

// Reducer for Bet Selections
const betReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTION':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'RESET':
      return { selectedAsset: '', amount: '', selectedResult: '' };
    default:
      return state;
  }
};

// Main PlaceBet Component
const PlaceBet = ({ bet, lucid }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isVerySmallScreen = useMediaQuery('(max-width:360px)');
  const walletAddress = bet.walletAddress;

  const [betSelections, dispatch] = useReducer(betReducer, {
    selectedAsset: '',
    amount: '',
    selectedResult: '',
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openGraphDialog, setOpenGraphDialog] = useState(false);
  const [popupDetails, setPopupDetails] = useState({});
  const [currentBetInfo, setCurrentBetInfo] = useState(null);

  const [walletBalances, setWalletBalances] = useState({
    ADA: 0,
    SNEK: 0,
    ERMO: 0,
    USDM: 0,
    GLDSNEK: 0,
    BabySNEK: 0,
    FREN: 0,
    BABYDONZO: 0,
  });

  const [resultStats, setResultStats] = useState({});
  const [transactionData, setTransactionData] = useState('');
  const [loading, setLoading] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const [selectedChartAsset, setSelectedChartAsset] = useState('ADA');
  const [selectedChartType, setSelectedChartType] = useState('bar');
  const chartRef = useRef(null);
  const [multiplier, setMultiplier] = useState(1);

  const [selectedSectionFilter, setSelectedSectionFilter] = useState('All');
  const [selectedAssetFilter, setSelectedAssetFilter] = useState('');

  const assetsList = ['ADA', 'SNEK', 'ERMO', 'USDM', 'GLDSNEK', 'BabySNEK', 'FREN', 'BABYDONZO'];

  const [bestMultiplier, setBestMultiplier] = useState(null);
  const [bestAsset, setBestAsset] = useState('');
  const [bestResult, setBestResult] = useState('');
  const [isBestMultiplierLoading, setIsBestMultiplierLoading] = useState(true);
  const [openExplanationDialog, setOpenExplanationDialog] = useState(false);

  const handleOpenExplanationDialog = () => {
    setOpenExplanationDialog(true);
  };

  const handleCloseExplanationDialog = () => {
    setOpenExplanationDialog(false);
  };

  // Calculate Multiplier Function - Moved to top to avoid ReferenceError
  const calculateMultiplierFor = useCallback(
    (selectedAsset, selectedResult) => {
      if (selectedAsset && selectedResult && resultStats[selectedResult]) {
        let selectedBet = resultStats[selectedResult]?.[selectedAsset]?.total || 0;

        if (selectedBet === 0) {
          selectedBet = 1;
        }

        const totalBetAcrossResults = Object.values(resultStats).reduce((sum, resultData) => {
          return sum + (resultData[selectedAsset]?.total || 0);
        }, 0);

        const totalBetWithMinOne = totalBetAcrossResults > 0 ? totalBetAcrossResults : 1;

        let multiplierCalculated = (totalBetWithMinOne / selectedBet) * 0.97;
        if (multiplierCalculated <= 1) {
          multiplierCalculated = 1;
        }
        return multiplierCalculated;
      } else {
        return 1;
      }
    },
    [resultStats]
  );

  // Calculate Multiplier After Bet
  const calculateMultiplierAfterBet = useCallback(() => {
    const { selectedAsset, selectedResult, amount } = betSelections;
    if (!selectedAsset || !selectedResult || !amount || isNaN(amount)) {
      return 1;
    }

    const currentBet = resultStats[selectedResult]?.[selectedAsset]?.total || 0;
    const newBet = parseFloat(amount) + currentBet;

    const totalBetAcrossResults = Object.values(resultStats).reduce((sum, resultData) => {
      return sum + (resultData[selectedAsset]?.total || 0);
    }, 0) + parseFloat(amount);

    const selectedBet = newBet > 0 ? newBet : 1;

    let newMultiplier = (totalBetAcrossResults / selectedBet) * 0.97;
    if (newMultiplier <= 1) {
      newMultiplier = 1;
    }
    return parseFloat(newMultiplier.toFixed(2));
  }, [betSelections, resultStats]);

  // Fetch Wallet Balances
  useEffect(() => {
    const fetchBalances = async () => {
      if (!walletAddress) {
        console.error('Wallet address is null or undefined.');
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(
          `https://cardano-mainnet.blockfrost.io/api/v0/addresses/${walletAddress}`,
          {
            headers: {
              project_id: process.env.REACT_APP_BLOCKFROST_API_KEY,
            },
          }
        );
        const balances = response.data.amount.reduce(
          (acc, asset) => {
            if (asset.unit === 'lovelace') {
              acc.ADA = Math.floor(parseInt(asset.quantity) / 1000000);
            } else if (asset.unit === '279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f534e454b') {
              acc.SNEK = parseInt(asset.quantity);
            } else if (
              asset.unit === 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f'
            ) {
              acc.ERMO = parseInt(asset.quantity);
            } else if (
              asset.unit === 'c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad0014df105553444d'
            ) {
              acc.USDM = parseFloat(parseInt(asset.quantity) / 1000000).toFixed(2);
            } else if (
              asset.unit === 'cdbbe391853676285131fe2de250e274dbeb5f9d98344e86c7b383d9474c44534e454b'
            ) {
              acc.GLDSNEK = parseInt(asset.quantity);
            } else if (
              asset.unit === '7507734918533b3b896241b4704f3d4ce805256b01da6fcede43043642616279534e454b'
            ) {
              acc.BabySNEK = parseInt(asset.quantity);
            } else if (
              asset.unit === 'fc11a9ef431f81b837736be5f53e4da29b9469c983d07f321262ce614652454e'
            ) {
              acc.FREN = parseInt(asset.quantity);
            } else if (
              asset.unit === '7f149ffd7b3311da196e7af94abdcb7f4ab889677ec1ecb888e32dac42414259444f4e5a4f'
            ) {
              acc.BABYDONZO = parseInt(asset.quantity);
            }
            return acc;
          },
          { ADA: 0, SNEK: 0, ERMO: 0, USDM: 0, GLDSNEK: 0, BabySNEK: 0, FREN: 0, BABYDONZO: 0 }
        );
        setWalletBalances({
          ADA: formatNumber(balances.ADA),
          SNEK: formatNumber(balances.SNEK),
          ERMO: formatNumber(balances.ERMO),
          USDM: formatNumber(parseFloat(balances.USDM)),
          GLDSNEK: formatNumber(balances.GLDSNEK),
          BabySNEK: formatNumber(balances.BabySNEK),
          FREN: formatNumber(balances.FREN),
          BABYDONZO: formatNumber(balances.BABYDONZO),
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching wallet balances:', error);
        setLoading(false);
      }
    };

    fetchBalances();
  }, [walletAddress]);

  // Fetch Transactions with Metadata
  useEffect(() => {
    const fetchTransactionsWithMetadata = async (walletAddress) => {
      try {
        const txResponse = await axios.get(
          `https://cardano-mainnet.blockfrost.io/api/v0/addresses/${walletAddress}/transactions`,
          {
            headers: {
              project_id: process.env.REACT_APP_BLOCKFROST_API_KEY,
            },
          }
        );
        const txData = txResponse.data;
        let txDetails = '';
        const resultStatistics = {};

        bet.result.forEach((result) => {
          resultStatistics[result] = {
            ADA: { count: 0, total: 0 },
            SNEK: { count: 0, total: 0 },
            ERMO: { count: 0, total: 0 },
            USDM: { count: 0, total: 0 },
            GLDSNEK: { count: 0, total: 0 },
            BabySNEK: { count: 0, total: 0 },
            FREN: { count: 0, total: 0 },
            BABYDONZO: { count: 0, total: 0 },
          };
        });

        for (let tx of txData) {
          const txMetadataResponse = await axios.get(
            `https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}/metadata`,
            {
              headers: {
                project_id: process.env.REACT_APP_BLOCKFROST_API_KEY,
              },
            }
          );

          const metadata = txMetadataResponse.data;
          if (metadata.length > 0) {
            const result = metadata[0].json_metadata.result;

            if (!resultStatistics[result]) {
              console.warn(`Result "${result}" not recognized for this bet.`);
              continue;
            }

            const txUtxoResponse = await axios.get(
              `https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}/utxos`,
              {
                headers: {
                  project_id: process.env.REACT_APP_BLOCKFROST_API_KEY,
                },
              }
            );
            const utxoData = txUtxoResponse.data;
            let bettedBalances = {
              ADA: 0,
              SNEK: 0,
              ERMO: 0,
              USDM: 0,
              GLDSNEK: 0,
              BabySNEK: 0,
              FREN: 0,
              BABYDONZO: 0,
            };

            utxoData.outputs.forEach((output) => {
              if (output.address === walletAddress) {
                output.amount.forEach((asset) => {
                  if (asset.unit === 'lovelace') {
                    bettedBalances.ADA += parseFloat(asset.quantity) / 1000000;
                  } else if (asset.unit === '279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f534e454b') {
                    bettedBalances.SNEK += parseInt(asset.quantity, 10);
                  } else if (
                    asset.unit === 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f'
                  ) {
                    bettedBalances.ERMO += parseInt(asset.quantity, 10);
                  } else if (
                    asset.unit === 'c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad0014df105553444d'
                  ) {
                    bettedBalances.USDM += parseFloat(asset.quantity) / 1000000;
                  } else if (
                    asset.unit === 'cdbbe391853676285131fe2de250e274dbeb5f9d98344e86c7b383d9474c44534e454b'
                  ) {
                    bettedBalances.GLDSNEK += parseInt(asset.quantity, 10);
                  } else if (
                    asset.unit === '7507734918533b3b896241b4704f3d4ce805256b01da6fcede43043642616279534e454b'
                  ) {
                    bettedBalances.BabySNEK += parseInt(asset.quantity, 10);
                  } else if (
                    asset.unit === 'fc11a9ef431f81b837736be5f53e4da29b9469c983d07f321262ce614652454e'
                  ) {
                    bettedBalances.FREN += parseInt(asset.quantity, 10);
                  } else if (
                    asset.unit === '7f149ffd7b3311da196e7af94abdcb7f4ab889677ec1ecb888e32dac42414259444f4e5a4f'
                  ) {
                    bettedBalances.BABYDONZO += parseInt(asset.quantity, 10);
                  }
                });
              }
            });

            // Zero out ADA if other assets are betted
            if (
              bettedBalances.ERMO > 0 ||
              bettedBalances.SNEK > 0 ||
              bettedBalances.USDM > 0 ||
              bettedBalances.GLDSNEK > 0 ||
              bettedBalances.BabySNEK > 0 ||
              bettedBalances.FREN > 0 ||
              bettedBalances.BABYDONZO > 0
            ) {
              bettedBalances.ADA = 0;
            }

            // Update resultStatistics
            if (bettedBalances.ADA > 0) {
              resultStatistics[result].ADA.count += 1;
              resultStatistics[result].ADA.total += bettedBalances.ADA;
            }
            if (bettedBalances.SNEK > 0) {
              resultStatistics[result].SNEK.count += 1;
              resultStatistics[result].SNEK.total += bettedBalances.SNEK;
            }
            if (bettedBalances.ERMO > 0) {
              resultStatistics[result].ERMO.count += 1;
              resultStatistics[result].ERMO.total += bettedBalances.ERMO;
            }
            if (bettedBalances.USDM > 0) {
              resultStatistics[result].USDM.count += 1;
              resultStatistics[result].USDM.total += bettedBalances.USDM;
            }
            if (bettedBalances.GLDSNEK > 0) {
              resultStatistics[result].GLDSNEK.count += 1;
              resultStatistics[result].GLDSNEK.total += bettedBalances.GLDSNEK;
            }
            if (bettedBalances.BabySNEK > 0) {
              resultStatistics[result].BabySNEK.count += 1;
              resultStatistics[result].BabySNEK.total += bettedBalances.BabySNEK;
            }
            if (bettedBalances.FREN > 0) {
              resultStatistics[result].FREN.count += 1;
              resultStatistics[result].FREN.total += bettedBalances.FREN;
            }
            if (bettedBalances.BABYDONZO > 0) {
              resultStatistics[result].BABYDONZO.count += 1;
              resultStatistics[result].BABYDONZO.total += bettedBalances.BABYDONZO;
            }

            if (showMetadata) {
              txDetails += `<div><strong>Transaction Hash:</strong> ${tx.tx_hash}<br>
                        <strong>Result:</strong> ${metadata[0].json_metadata.result}<br>
                        <strong>Betted Balances:</strong><br>
                        ${
                          bettedBalances.ADA > 0
                            ? `ADA: ${formatNumber(bettedBalances.ADA)}<br>`
                            : ''
                        }
                        ${
                          bettedBalances.SNEK > 0 ? `SNEK: ${formatNumber(bettedBalances.SNEK)}<br>` : ''
                        }
                        ${
                          bettedBalances.ERMO > 0 ? `ERMO: ${formatNumber(bettedBalances.ERMO)}<br>` : ''
                        }
                        ${
                          bettedBalances.USDM > 0 ? `USDM: ${formatNumber(bettedBalances.USDM)}<br>` : ''
                        }
                        ${
                          bettedBalances.GLDSNEK > 0
                            ? `GLDSNEK: ${formatNumber(bettedBalances.GLDSNEK)}<br>`
                            : ''
                        }
                        ${
                          bettedBalances.BabySNEK > 0
                            ? `BabySNEK: ${formatNumber(bettedBalances.BabySNEK)}<br>`
                            : ''
                        }
                        ${
                          bettedBalances.FREN > 0
                            ? `FREN: ${formatNumber(bettedBalances.FREN)}<br>`
                            : ''
                        }
                        ${
                          bettedBalances.BABYDONZO > 0
                            ? `BABYDONZO: ${formatNumber(bettedBalances.BABYDONZO)}<br>`
                            : ''
                        }
                        </div>`;
            }
          }

          // Ensure that setResultStats and setTransactionData are outside the for-loop
        }

        setResultStats(resultStatistics);
        setTransactionData(txDetails);
      } catch (error) {
        console.error('Error fetching transactions with metadata:', error);
      }
    };

    if (walletAddress) {
      fetchTransactionsWithMetadata(walletAddress);
    }
  }, [walletAddress, bet.id, showMetadata, bet.result]);

  // Compute Asset with Most Transactions
  const [assetWithMostTransactions, setAssetWithMostTransactions] = useState('');

  useEffect(() => {
    const assetTransactionCounts = {};
    assetsList.forEach((asset) => {
      const totalBets = Object.values(resultStats).reduce(
        (sum, resultData) => sum + (resultData[asset]?.count || 0),
        0
      );
      assetTransactionCounts[asset] = totalBets;
    });
    const maxAsset = Object.keys(assetTransactionCounts).reduce((maxAsset, asset) => {
      return assetTransactionCounts[asset] > assetTransactionCounts[maxAsset] ? asset : maxAsset;
    }, assetsList[0]);
    setAssetWithMostTransactions(maxAsset);
  }, [resultStats]);

  // Prepare Pie Chart Data
  const pieChartData = useMemo(() => {
    if (!assetWithMostTransactions) return null;
    const labels = Object.keys(resultStats);
    const data = labels.map(
      (result) => resultStats[result][assetWithMostTransactions]?.total || 0
    );
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: labels.map((result, index) => {
            const colors = [
              'rgba(54, 162, 235, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)',
              'rgba(0, 255, 0, 0.6)',
              'rgba(255, 105, 180, 0.6)',
              'rgba(199, 199, 199, 0.6)',
              'rgba(83, 102, 255, 0.6)',
              'rgba(255, 205, 86, 0.6)',
            ];
            return colors[index % colors.length];
          }),
          borderColor: theme.palette.text.primary,
          borderWidth: 1,
        },
      ],
    };
  }, [assetWithMostTransactions, resultStats, theme.palette.text.primary]);

  // Calculate Multiplier
  useEffect(() => {
    const calculateMultiplier = () => {
      const { selectedAsset, selectedResult } = betSelections;
      if (selectedAsset && selectedResult && resultStats[selectedResult]) {
        let selectedBet = resultStats[selectedResult]?.[selectedAsset]?.total || 0;

        if (selectedBet === 0) {
          selectedBet = 1;
        }

        const totalBetAcrossResults = Object.values(resultStats).reduce((sum, resultData) => {
          return sum + (resultData[selectedAsset]?.total || 0);
        }, 0);

        const totalBetWithMinOne = totalBetAcrossResults > 0 ? totalBetAcrossResults : 1;

        let multiplierCalculated = (totalBetWithMinOne / selectedBet) * 0.97;
        if (multiplierCalculated <= 1) {
          multiplierCalculated = 1;
        }
        const truncatedMultiplier = parseFloat(multiplierCalculated.toFixed(2));
        setMultiplier(truncatedMultiplier);
      } else {
        setMultiplier(1);
      }
    };

    calculateMultiplier();
  }, [betSelections, resultStats]);

  // Calculate Best Multiplier
  useEffect(() => {
    const calculateBestMultiplier = () => {
      setIsBestMultiplierLoading(true);
      let maxMultiplier = 1;
      let maxAsset = '';
      let maxResult = '';

      Object.keys(resultStats).forEach((result) => {
        Object.keys(resultStats[result]).forEach((asset) => {
          const currentMultiplier = calculateMultiplierFor(asset, result);
          if (currentMultiplier > maxMultiplier) {
            maxMultiplier = currentMultiplier;
            maxAsset = asset;
            maxResult = result;
          }
        });
      });

      if (maxMultiplier <= 1) {
        maxMultiplier = 1;
      }

      setBestMultiplier(parseFloat(maxMultiplier.toFixed(2)));
      setBestAsset(maxAsset);
      setBestResult(maxResult);
      setIsBestMultiplierLoading(false);
    };

    if (Object.keys(resultStats).length > 0) {
      calculateBestMultiplier();
    }
  }, [resultStats, calculateMultiplierFor]);

  // Handle Bet Selection Changes
  const handleSelectionChange = (key, value) => {
    dispatch({ type: 'SET_SELECTION', payload: { key, value } });
  };

  // Handle Place Bet Action
  const handlePlaceBetAction = async () => {
    const { selectedAsset, amount, selectedResult } = betSelections;
    if (!lucid || !walletAddress) {
      alert('Please connect your wallet first.');
      return;
    }

    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount.');
      return;
    }

    const currentBet = resultStats[selectedResult]?.[selectedAsset]?.total || 0;
    const threshold = 2 * currentBet;

    if (parseFloat(amount) >= threshold) {
      const isFirst = currentBet === 0;
      setIsFirstBet(isFirst);

      const newMultiplier = calculateMultiplierAfterBet();
      setMultiplierAfterBet(newMultiplier);
      setOpenMultiplierChangeDialog(true);
    } else {
      proceedToPlaceBet();
    }
  };

  // Proceed to Place Bet
  const proceedToPlaceBet = useCallback(async () => {
    const { selectedAsset, amount, selectedResult } = betSelections;
    if (!lucid || !walletAddress) {
      alert('Please connect your wallet first.');
      return;
    }

    try {
      let assetPolicy = '';
      let assetNameHex = '';
      let paymentValue = {};

      switch (selectedAsset) {
        case 'ADA':
          paymentValue = { lovelace: BigInt(amount * 1000000) };
          break;
        case 'SNEK':
          assetPolicy = '279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f534e454b';
          assetNameHex = '534e454b'; // 'SNEK' in hex
          paymentValue = { [`${assetPolicy}${assetNameHex}`]: BigInt(amount) };
          break;
        case 'ERMO':
          assetPolicy = 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f';
          assetNameHex = '45524d4f'; // 'ERMO' in hex
          paymentValue = { [`${assetPolicy}${assetNameHex}`]: BigInt(amount) };
          break;
        case 'USDM':
          assetPolicy = 'c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad0014df105553444d';
          assetNameHex = '5553444d'; // 'USDM' in hex
          paymentValue = { [`${assetPolicy}${assetNameHex}`]: BigInt(amount * 1000000) };
          break;
        case 'GLDSNEK':
          assetPolicy = 'cdbbe391853676285131fe2de250e274dbeb5f9d98344e86c7b383d9474c44534e454b';
          assetNameHex = '474c44534e454b'; // 'GLDSNEK' in hex
          paymentValue = { [`${assetPolicy}${assetNameHex}`]: BigInt(amount) };
          break;
        case 'BabySNEK':
          assetPolicy = '7507734918533b3b896241b4704f3d4ce805256b01da6fcede43043642616279534e454b';
          assetNameHex = '42616279534e454b'; // 'BabySNEK' in hex
          paymentValue = { [`${assetPolicy}${assetNameHex}`]: BigInt(amount) };
          break;
        case 'FREN':
          assetPolicy = 'fc11a9ef431f81b837736be5f53e4da29b9469c983d07f321262ce614652454e';
          assetNameHex = '4652454e'; // 'FREN' in hex
          paymentValue = { [`${assetPolicy}${assetNameHex}`]: BigInt(amount) };
          break;
        case 'BABYDONZO':
          assetPolicy = '7f149ffd7b3311da196e7af94abdcb7f4ab889677ec1ecb888e32dac42414259444f4e5a4f';
          assetNameHex = '42414259444f4e5a4f'; // 'BABYDONZO' in hex
          paymentValue = { [`${assetPolicy}${assetNameHex}`]: BigInt(amount) };
          break;
        default:
          alert('Selected asset is not supported.');
          return;
      }

      const tx = await lucid
        .newTx()
        .payToAddress(bet.walletAddress, paymentValue)
        .attachMetadata(1, { betId: bet.id, result: selectedResult })
        .complete();

      const signedTx = await tx.sign().complete();
      const txHash = await signedTx.submit();
      console.log('Transaction submitted:', txHash);

      setPopupDetails({
        txHash,
        amount: formatNumber(parseFloat(amount)),
        selectedAsset,
        selectedResult,
        multiplier: formatNumber(parseFloat(multiplier)),
      });
      setOpenPopup(true);
      dispatch({ type: 'RESET' }); // Reset selections after placing bet
    } catch (error) {
      console.error('Error placing bet:', error.message);
      alert(`Error placing bet: ${error.message}`);
    }
  }, [lucid, walletAddress, bet.walletAddress, bet.id, betSelections, multiplier, resultStats, calculateMultiplierFor]);

  const [openMultiplierChangeDialog, setOpenMultiplierChangeDialog] = useState(false);
  const [multiplierAfterBet, setMultiplierAfterBet] = useState(1);
  const [isFirstBet, setIsFirstBet] = useState(false);

  const handleConfirmMultiplierChange = () => {
    setOpenMultiplierChangeDialog(false);
    proceedToPlaceBet();
  };

  const handleCancelMultiplierChange = () => {
    setOpenMultiplierChangeDialog(false);
  };

  // Download Transaction Details as JPG
  const handleDownload = () => {
    const element = document.getElementById('popup-details');
    html2canvas(element, { backgroundColor: '#fff' }).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'transaction-details.jpg';
      link.href = canvas.toDataURL('image/jpeg');
      link.click();
    });
  };

  // Asset Options
  const assetOptions = useMemo(
    () => [
      { label: 'ADA', icon: '/ada.png' },
      { label: 'ERMO', icon: '/ermo.png' },
      { label: 'SNEK', icon: '/snek.png' },
      { label: 'USDM', icon: '/usdm.png' },
      { label: 'GLDSNEK', icon: '/GLDSNEK.png' },
      { label: 'BabySNEK', icon: '/bbsnek.png' },
      { label: 'FREN', icon: '/fren.png' },
      { label: 'BABYDONZO', icon: '/babydonzo.png' },
    ],
    []
  );

  // Handle Info Click
  const handleInfoClick = () => {
    setCurrentBetInfo(bet);
    setSelectedSectionFilter('All');
    setSelectedAssetFilter('');
    setOpenDialog(true);
  };

  // Handle Graph Click
  const handleGraphClick = () => {
    setOpenGraphDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentBetInfo(null);
  };

  const handleCloseGraphDialog = () => {
    setOpenGraphDialog(false);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleChartAssetChange = (event) => {
    setSelectedChartAsset(event.target.value);
  };

  const handleChartTypeChange = (event) => {
    setSelectedChartType(event.target.value);
  };

  // Chart Data
  const chartData = useMemo(
    () => ({
      labels: Object.keys(resultStats),
      datasets: [
        {
          label: `${selectedChartAsset} Transactions`,
          data: Object.keys(resultStats).map(
            (result) => resultStats[result][selectedChartAsset]?.total || 0
          ),
          backgroundColor: {
            ADA: 'rgba(54, 162, 235, 0.6)',
            ERMO: 'rgba(54, 162, 235, 0.6)',
            SNEK: 'rgba(153, 102, 255, 0.6)',
            USDM: 'rgba(255, 206, 86, 0.6)',
            GLDSNEK: 'rgba(75, 192, 192, 0.6)',
            BabySNEK: 'rgba(255, 159, 64, 0.6)',
            FREN: 'rgba(0, 255, 0, 0.6)',
            BABYDONZO: 'rgba(255, 105, 180, 0.6)',
          }[selectedChartAsset],
          borderColor: theme.palette.text.primary,
          borderWidth: 1,
          borderRadius: 5,
        },
      ],
    }),
    [resultStats, selectedChartAsset, theme.palette.text.primary]
  );

  const renderChart = () => {
    switch (selectedChartType) {
      case 'line':
        return (
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  labels: {
                    font: {
                      family: "'Montserrat', sans-serif",
                      size: 14,
                    },
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    font: {
                      family: "'Montserrat', sans-serif",
                      size: 12,
                    },
                    autoSkip: true,
                    maxTicksLimit: 10,
                  },
                },
                y: {
                  ticks: {
                    callback: function (value) {
                      return formatNumber(parseFloat(value));
                    },
                    font: {
                      family: "'Montserrat', sans-serif",
                      size: 12,
                    },
                  },
                },
              },
            }}
          />
        );
      case 'pie':
        return (
          <Pie
            data={{
              labels: Object.keys(resultStats),
              datasets: [
                {
                  data: Object.keys(resultStats).map(
                    (result) => resultStats[result][selectedChartAsset]?.total || 0
                  ),
                  backgroundColor: Object.keys(resultStats).map((result, index) => {
                    const colors = [
                      'rgba(54, 162, 235, 0.6)',
                      'rgba(75, 192, 192, 0.6)',
                      'rgba(255, 206, 86, 0.6)',
                      'rgba(153, 102, 255, 0.6)',
                      'rgba(255, 159, 64, 0.6)',
                      'rgba(255, 99, 132, 0.6)',
                      'rgba(0, 255, 0, 0.6)',
                      'rgba(255, 105, 180, 0.6)',
                      'rgba(199, 199, 199, 0.6)',
                      'rgba(83, 102, 255, 0.6)',
                      'rgba(255, 205, 86, 0.6)',
                    ];
                    return colors[index % colors.length];
                  }),
                  borderColor: theme.palette.text.primary,
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  labels: {
                    font: {
                      family: "'Montserrat', sans-serif",
                      size: 14,
                    },
                  },
                },
              },
            }}
          />
        );
      default:
        return (
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  labels: {
                    font: {
                      family: "'Montserrat', sans-serif",
                      size: 14,
                    },
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    font: {
                      family: "'Montserrat', sans-serif",
                      size: 12,
                    },
                    autoSkip: true,
                    maxTicksLimit: 10,
                  },
                },
                y: {
                  ticks: {
                    callback: function (value) {
                      return formatNumber(parseFloat(value));
                    },
                    font: {
                      family: "'Montserrat', sans-serif",
                      size: 12,
                    },
                  },
                },
              },
            }}
          />
        );
    }
  };

  const isBetOver = useMemo(() => new Date(bet.endDate).getTime() <= Date.now(), [bet.endDate]);
  const isClosedBet = bet.type === 'Closed';

  const handleCopyPayout = () => {
    navigator.clipboard.writeText(bet.payout).then(
      () => {
        alert('Payout hash copied to clipboard!');
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <BetContainer>
          <BetHeader>
            <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%" gap="15px">
              <AvatarWithBorder alt="Profile Picture" src={bet.profilePicture} />
              <TitleTypography variant="h6">{`${bet.type} - ${bet.title}`}</TitleTypography>
            </Box>
            <Box display="flex" alignItems="center" gap="15px" justifyContent="center" width="100%">
              {isBestMultiplierLoading ? (
                <CircularProgress size={24} />
              ) : (
                <BestMultiplierTypography variant="body2">
                  Best Multiplier: x{bestMultiplier}
                </BestMultiplierTypography>
              )}
              {!isClosedBet && <Timer endTime={new Date(bet.endDate).getTime()} />}
            </Box>
          </BetHeader>
        </BetContainer>
      </AccordionSummary>
      <AccordionDetails>
        <BetContainer>
          <DescriptionTypography variant="body1">{bet.description}</DescriptionTypography>

          {/* Timer & Multiplier Section */}
          {!isClosedBet && (
            <TimerMultiplierSection>
              <MultiplierContainer>
                <Avatar
                  alt="Multiplier Icon"
                  src={
                    betSelections.selectedAsset
                      ? `/${betSelections.selectedAsset.toLowerCase()}.png`
                      : bestAsset
                      ? `/${bestAsset.toLowerCase()}.png`
                      : '/default.png'
                  }
                  sx={{
                    width: isMobile ? 60 : 80,
                    height: isMobile ? 60 : 80,
                    marginBottom: '15px',
                  }}
                />
                {isBestMultiplierLoading ? (
                  <CircularProgress size={28} />
                ) : betSelections.selectedAsset &&
                  betSelections.selectedResult &&
                  betSelections.amount ? (
                  <>
                    <MultiplierValue>x{multiplier}</MultiplierValue>
                    <MultiplierLabel>Current Multiplier</MultiplierLabel>
                  </>
                ) : (
                  <>
                    <MultiplierValue>x{bestMultiplier}</MultiplierValue>
                    <MultiplierLabel>Best Multiplier</MultiplierLabel>
                    {bestResult && (
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          color: theme.palette.text.secondary,
                          fontSize: isMobile ? '0.9rem' : '1rem',
                        }}
                      >
                        Result: {bestResult}
                      </Typography>
                    )}
                  </>
                )}
              </MultiplierContainer>

              {!isMobile && (
                <>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.default,
                      borderRadius: '10px',
                      padding: '15px',
                      border: `1px solid ${theme.palette.text.primary}`,
                      boxShadow: theme.shadows[3],
                      width: '100%',
                      maxWidth: '400px',
                      height: '400px', // Set the same height
                      flex: '1 1 400px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontFamily: "'Montserrat', sans-serif",
                        color: theme.palette.text.primary,
                        marginBottom: '15px',
                        textAlign: 'center',
                      }}
                    >
                      Current Bet Statistics
                    </Typography>
                    <TableContainer component={Paper} elevation={0}>
                      <Table size="small" aria-label="Bet Statistics Table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 600,
                                fontSize: '1rem',
                              }}
                            >
                              Asset
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 600,
                                fontSize: '1rem',
                              }}
                              align="right"
                            >
                              Total Bets
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 600,
                                fontSize: '1rem',
                              }}
                              align="right"
                            >
                              Total Amount
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {assetsList.map((asset) => {
                            const totalBets = Object.values(resultStats).reduce(
                              (sum, resultData) => sum + (resultData[asset]?.count || 0),
                              0
                            );
                            const totalAmount = Object.values(resultStats).reduce(
                              (sum, resultData) => sum + (resultData[asset]?.total || 0),
                              0
                            );
                            return (
                              <TableRow key={asset}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ fontFamily: "'Montserrat', sans-serif" }}
                                >
                                  {asset}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ fontFamily: "'Montserrat', sans-serif" }}
                                >
                                  {formatNumber(totalBets)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ fontFamily: "'Montserrat', sans-serif" }}
                                >
                                  {formatNumber(totalAmount)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  {/* Pie Chart for Asset with Most Transactions */}
                  {assetWithMostTransactions && pieChartData && (
                    <Box
                      sx={{
                        backgroundColor: theme.palette.background.default,
                        borderRadius: '10px',
                        padding: '15px',
                        border: `1px solid ${theme.palette.text.primary}`,
                        boxShadow: theme.shadows[3],
                        width: '100%',
                        maxWidth: '400px',
                        height: '400px', // Set the same height
                        flex: '1 1 400px',
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          color: theme.palette.text.primary,
                          marginBottom: '15px',
                          textAlign: 'center',
                        }}
                      >
                        {`Bet Distribution for ${assetWithMostTransactions}`}
                      </Typography>
                      <Box
                        sx={{
                          width: '100%',
                          height: '300px',
                        }}
                      >
                        <Pie data={pieChartData} options={{}} />
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </TimerMultiplierSection>
          )}

          {/* Place Bet Form */}
          {!isBetOver && !isClosedBet && (
            <Box>
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} sm={4} md={4}>
                  <StyledSelect
                    value={betSelections.selectedAsset}
                    onChange={(e) => handleSelectionChange('selectedAsset', e.target.value)}
                    displayEmpty
                    aria-label="Select Asset"
                    variant="outlined"
                  >
                    <MenuItem value="" disabled>
                      Select Asset
                    </MenuItem>
                    {assetOptions.map((asset) => (
                      <MenuItem key={asset.label} value={asset.label}>
                        <Box display="flex" alignItems="center">
                          <AssetIcon src={asset.icon} alt={asset.label} />
                          <span>{asset.label}</span>
                        </Box>
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <StyledNumberField
                    type="number"
                    value={betSelections.amount}
                    onChange={(e) => handleSelectionChange('amount', e.target.value)}
                    inputProps={{ min: '0', step: 'any' }}
                    placeholder="Enter amount"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {betSelections.selectedAsset}
                        </InputAdornment>
                      ),
                      'aria-label': 'Enter Bet Amount',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <StyledSelect
                    value={betSelections.selectedResult}
                    onChange={(e) => handleSelectionChange('selectedResult', e.target.value)}
                    displayEmpty
                    aria-label="Select Result"
                    variant="outlined"
                  >
                    <MenuItem value="" disabled>
                      Select Result
                    </MenuItem>
                    {bet.result.map((result) => (
                      <MenuItem key={result} value={result}>
                        {result}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Grid>
              </Grid>

              <Box
                marginTop="15px"
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                justifyContent="flex-start"
              >
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    color: theme.palette.text.primary,
                    fontSize: '1.1rem',
                  }}
                >
                  Potential Payout:{' '}
                  {(multiplier * parseFloat(betSelections.amount || 0)).toFixed(2)}{' '}
                  {betSelections.selectedAsset}
                </Typography>
                <Tooltip title="Learn more about multipliers">
                  <IconButton
                    onClick={handleOpenExplanationDialog}
                    style={{
                      width: '40px',
                      height: '40px',
                      fontSize: '18px',
                      padding: 0,
                      marginLeft: '10px',
                      backgroundColor: theme.palette.secondary.main,
                      color: '#fff',
                      boxShadow: theme.shadows[2],
                      border: `1px solid ${theme.palette.text.primary}`,
                      transition: 'background-color 0.3s, color 0.3s',
                      borderRadius: '50%',
                    }}
                    aria-label="Learn more about multipliers"
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* Action Buttons */}
              <ActionButtonsContainer>
                <StyledButton
                  onClick={handlePlaceBetAction}
                  disabled={isBetOver || loading}
                  startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
                  aria-label="Place Bet"
                >
                  {loading
                    ? isVerySmallScreen
                      ? 'Placing...'
                      : 'Placing Bet...'
                    : isVerySmallScreen
                    ? 'Bet'
                    : 'Place Bet'}
                </StyledButton>
                <StyledButton
                  onClick={handleInfoClick}
                  startIcon={<InfoIcon />}
                  aria-label="View Info"
                >
                  {isVerySmallScreen ? 'Info' : 'Info'}
                </StyledButton>
                <StyledButton
                  onClick={handleGraphClick}
                  startIcon={<DownloadIcon />}
                  aria-label="View Graph"
                >
                  {isVerySmallScreen ? 'Graph' : 'Graph'}
                </StyledButton>
              </ActionButtonsContainer>
            </Box>
          )}

          {/* Payout Section for Closed Bets */}
          {isClosedBet && (
            <Box marginTop="20px">
              <Typography
                variant="body1"
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  color: theme.palette.text.primary,
                  fontSize: '1.2rem',
                  wordBreak: 'break-word',
                  textAlign: 'left',
                }}
              >
                <strong>Payout:</strong> {bet.payout}
                <Tooltip title="Copy to clipboard">
                  <IconButton
                    onClick={handleCopyPayout}
                    style={{
                      marginLeft: '10px',
                      padding: '8px',
                      backgroundColor: theme.palette.secondary.main,
                      color: '#fff',
                      boxShadow: theme.shadows[2],
                      border: `1px solid ${theme.palette.text.primary}`,
                      transition: 'background-color 0.3s, color 0.3s',
                      borderRadius: '50%',
                    }}
                    aria-label="Copy Payout"
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Box>
          )}

          {/* Closed Bet Payout Info */}
          {!isClosedBet && isBetOver && (
            <Box marginTop="20px">
              <Typography
                variant="body1"
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  color: theme.palette.text.primary,
                  fontSize: '1.2rem',
                  textAlign: 'left',
                }}
              >
                <strong>Payout:</strong> {bet.payout}
              </Typography>
            </Box>
          )}

          {/* Information Dialog */}
          {currentBetInfo && (
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
              <DialogTitle>
                {`${currentBetInfo.title} - Details`}
                <IconButton
                  aria-label="close"
                  onClick={handleCloseDialog}
                  sx={{
                    position: 'absolute',
                    right: 16,
                    top: 16,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <DescriptionTypography variant="body1">
                  <strong>Description:</strong> {currentBetInfo.description}
                </DescriptionTypography>
                <Typography variant="h6" gutterBottom>
                  Available Results:
                </Typography>
                <Grid container spacing={2} marginBottom="20px" justifyContent="flex-start">
                  {currentBetInfo.result.map((result) => (
                    <Grid item key={result} xs={6} sm={4} md={3}>
                      <BoxedAsset>
                        <AssetIcon src={`/${selectedChartAsset.toLowerCase()}.png`} alt={result} />
                        <Typography
                          variant="body2"
                          style={{
                            fontFamily: "'Montserrat', sans-serif",
                            marginLeft: '8px',
                          }}
                        >
                          {result}
                        </Typography>
                      </BoxedAsset>
                    </Grid>
                  ))}
                </Grid>

                {/* Section Filters */}
                <Grid container spacing={2} marginBottom="20px" justifyContent="flex-start">
                  <Grid item xs={12} sm={6}>
                    <StyledSelect
                      value={selectedSectionFilter}
                      onChange={(e) => setSelectedSectionFilter(e.target.value)}
                      displayEmpty
                      aria-label="Filter Sections"
                    >
                      <MenuItem value="All">
                        <em>All Sections</em>
                      </MenuItem>
                      <MenuItem value="Bet Balance">Bet Balance</MenuItem>
                      <MenuItem value="Result Statistics">Result Statistics</MenuItem>
                      <MenuItem value="Multipliers">Multipliers</MenuItem>
                    </StyledSelect>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledSelect
                      value={selectedAssetFilter}
                      onChange={(e) => setSelectedAssetFilter(e.target.value)}
                      displayEmpty
                      aria-label="Filter Assets"
                    >
                      <MenuItem value="">
                        <em>All Assets</em>
                      </MenuItem>
                      {assetOptions.map((asset) => (
                        <MenuItem key={asset.label} value={asset.label}>
                          <Box display="flex" alignItems="center">
                            <AssetIcon src={asset.icon} alt={asset.label} />
                            <span>{asset.label}</span>
                          </Box>
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Grid>
                </Grid>

                {/* Conditional Sections */}
                {(selectedSectionFilter === 'All' || selectedSectionFilter === 'Bet Balance') && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Bet Balance:
                    </Typography>
                    <Grid container spacing={2} justifyContent="flex-start">
                      {assetsList
                        .filter((asset) => !selectedAssetFilter || selectedAssetFilter === asset)
                        .map((asset) => (
                          <Grid item xs={6} sm={4} md={3} key={asset}>
                            <BoxedAsset>
                              <AssetIcon src={`/${asset.toLowerCase()}.png`} alt={asset} />
                              <Typography
                                variant="body2"
                                style={{
                                  fontFamily: "'Montserrat', sans-serif",
                                  marginLeft: '8px',
                                }}
                              >
                                {asset}: {formatNumber(walletBalances[asset])}
                              </Typography>
                            </BoxedAsset>
                          </Grid>
                        ))}
                    </Grid>
                  </>
                )}

                {(selectedSectionFilter === 'All' || selectedSectionFilter === 'Result Statistics') && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Result Statistics:
                    </Typography>
                    <Grid container spacing={2} justifyContent="flex-start">
                      {Object.entries(resultStats).map(([result, assets]) => (
                        <Grid item xs={12} key={result}>
                          <Typography variant="subtitle1" gutterBottom align="left">
                            {result}
                          </Typography>
                          <Grid container spacing={1} justifyContent="flex-start">
                            {assetsList
                              .filter((asset) => !selectedAssetFilter || selectedAssetFilter === asset)
                              .map((asset) => (
                                <Grid item xs={6} sm={4} md={3} key={asset}>
                                  <BoxedAsset>
                                    <AssetIcon src={`/${asset.toLowerCase()}.png`} alt={asset} />
                                    <Typography
                                      variant="body2"
                                      style={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        marginLeft: '8px',
                                      }}
                                    >
                                      {asset}: {formatNumber(parseFloat(assets[asset].total))}
                                    </Typography>
                                  </BoxedAsset>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}

                {(selectedSectionFilter === 'All' || selectedSectionFilter === 'Multipliers') && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Multipliers:
                    </Typography>
                    <Grid container spacing={2} justifyContent="flex-start">
                      {Object.entries(resultStats).map(([result, assetsData]) => (
                        <Grid item xs={12} key={result}>
                          <Typography variant="subtitle1" gutterBottom align="left">
                            {result}
                          </Typography>
                          <Grid container spacing={1} justifyContent="flex-start">
                            {assetsList
                              .filter((asset) => !selectedAssetFilter || selectedAssetFilter === asset)
                              .map((asset) => (
                                <Grid item xs={6} sm={4} md={3} key={asset}>
                                  <BoxedAsset>
                                    <AssetIcon src={`/${asset.toLowerCase()}.png`} alt={asset} />
                                    <Typography
                                      variant="body2"
                                      style={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        marginLeft: '8px',
                                      }}
                                    >
                                      {asset}: x
                                      {formatNumber(parseFloat(calculateMultiplierFor(asset, result)))}
                                    </Typography>
                                  </BoxedAsset>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
                  <DownloadButton2
                    transactions={[
                      {
                        Description: currentBetInfo.description,
                        'Available Results': currentBetInfo.result,
                        'Bet Balance': walletBalances,
                        'Result Statistics': resultStats,
                        Multipliers: Object.keys(resultStats).reduce((acc, result) => {
                          acc[result] = {};
                          assetsList.forEach((asset) => {
                            acc[result][asset] = calculateMultiplierFor(asset, result);
                          });
                          return acc;
                        }, {}),
                      },
                    ]}
                  />
                  <StyledButton onClick={handleCloseDialog}>Close</StyledButton>
                </Box>
              </DialogActions>
            </Dialog>
          )}

          {/* Graph Dialog */}
          <Dialog open={openGraphDialog} onClose={handleCloseGraphDialog} maxWidth="lg" fullWidth>
            <DialogTitle>
              Graph
              <IconButton
                aria-label="close"
                onClick={handleCloseGraphDialog}
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: 16,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={3} marginBottom="20px" justifyContent="flex-start">
                <Grid item xs={12} sm={6}>
                  <StyledSelect
                    value={selectedChartAsset}
                    onChange={handleChartAssetChange}
                    displayEmpty
                    aria-label="Select Asset for Chart"
                  >
                    <MenuItem value="" disabled>
                      Select Asset for Chart
                    </MenuItem>
                    {assetOptions.map((asset) => (
                      <MenuItem key={asset.label} value={asset.label}>
                        <Box display="flex" alignItems="center">
                          <AssetIcon src={asset.icon} alt={asset.label} />
                          <span>{asset.label}</span>
                        </Box>
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledSelect
                    value={selectedChartType}
                    onChange={handleChartTypeChange}
                    displayEmpty
                    aria-label="Select Chart Type"
                  >
                    <MenuItem value="bar">Bar</MenuItem>
                    <MenuItem value="line">Line</MenuItem>
                    <MenuItem value="pie">Pie</MenuItem>
                  </StyledSelect>
                </Grid>
              </Grid>
              <Box
                sx={{
                  width: '100%',
                  height: '400px', // Set the same height as MultiplierContainer
                  overflowX: 'hidden',
                  backgroundColor: theme.palette.background.default,
                  borderRadius: '10px',
                  padding: '15px',
                  boxShadow: theme.shadows[3],
                }}
                ref={chartRef}
              >
                {renderChart()}
              </Box>
            </DialogContent>
            <DialogActions>
              <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
                <DownloadButton3 chartRef={chartRef} />
                <StyledButton onClick={handleCloseGraphDialog}>Close</StyledButton>
              </Box>
            </DialogActions>
          </Dialog>

          {/* Transaction Popup */}
          {openPopup && (
            <PopupDialogStyled open={openPopup} onClose={handleClosePopup}>
              <DialogTitle>Transaction Successful!</DialogTitle>
              <DialogContent dividers>
                <Box id="popup-details">
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      marginBottom: '15px',
                      wordBreak: 'break-word',
                      textAlign: 'left',
                    }}
                  >
                    <strong>Transaction Hash:</strong> {popupDetails.txHash}
                    <Tooltip title="Copy to clipboard">
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(popupDetails.txHash);
                        }}
                        style={{
                          marginLeft: '10px',
                          padding: '8px',
                          backgroundColor: theme.palette.secondary.main,
                          color: '#fff',
                          boxShadow: theme.shadows[2],
                          border: `1px solid ${theme.palette.text.primary}`,
                          transition: 'background-color 0.3s, color 0.3s',
                          borderRadius: '50%',
                        }}
                        aria-label="Copy Transaction Hash"
                      >
                        <ContentPasteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      marginBottom: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <strong>Amount:</strong> {popupDetails.amount} {popupDetails.selectedAsset}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      marginBottom: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <strong>Multiplier:</strong> x{popupDetails.multiplier}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      marginBottom: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <strong>Selected Result:</strong> {popupDetails.selectedResult}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      marginBottom: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <strong>Bet Title:</strong> {bet.title}
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
                  <StyledButton onClick={handleDownload} startIcon={<DownloadIcon />}>
                    Download as JPG
                  </StyledButton>
                  <StyledButton onClick={handleClosePopup}>Close</StyledButton>
                </Box>
              </DialogActions>
            </PopupDialogStyled>
          )}

          {/* Multiplier Change Dialog */}
          <MultiplierChangeDialogStyled
            open={openMultiplierChangeDialog}
            onClose={handleCancelMultiplierChange}
            aria-labelledby="multiplier-change-dialog-title"
            aria-describedby="multiplier-change-dialog-description"
          >
            <DialogTitle id="multiplier-change-dialog-title">Multiplier Change Notice</DialogTitle>
            <DialogContent dividers>
              <Typography
                variant="body1"
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: '1.4rem',
                  textAlign: 'left',
                }}
              >
                You are placing a bet that is <strong>2x or more</strong> of the current bet balance for{' '}
                <strong>{betSelections.selectedAsset}</strong>. This will change the multiplier.
              </Typography>
              <Box marginTop="20px">
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: '1.1rem',
                    textAlign: 'left',
                  }}
                >
                  <strong>New Multiplier After Bet:</strong> x{multiplierAfterBet}
                </Typography>
              </Box>
              {isFirstBet && (
                <Box
                  marginTop="25px"
                  padding="20px"
                  bgcolor="#FFF8E1"
                  borderRadius="10px"
                  border={`1px solid #FFB300`}
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      color: '#FF6F00',
                      fontSize: '1.1rem',
                      textAlign: 'left',
                    }}
                  >
                    You are the first to place a bet on this result! If no additional bets are made,
                    your original bet will be <strong>refunded</strong>, plus you'll receive a bonus
                    of x0.1 Ermo based on your bet amount.
                  </Typography>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
                <StyledButton onClick={handleConfirmMultiplierChange}>Confirm</StyledButton>
                <StyledButton onClick={handleCancelMultiplierChange}>Cancel</StyledButton>
              </Box>
            </DialogActions>
          </MultiplierChangeDialogStyled>

          {/* Explanation Dialog */}
          <MultiplierExplanationDialog
            open={openExplanationDialog}
            handleClose={handleCloseExplanationDialog}
          />
        </BetContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default PlaceBet;
