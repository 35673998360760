// src/WalletProvider.js

import React, { createContext, useState, useEffect } from 'react';
import { Lucid, Blockfrost } from 'lucid-cardano';

// Create the Wallet Context
export const WalletContext = createContext();

// WalletProvider Component
const WalletProvider = ({ children }) => {
  const [lucid, setLucid] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true); // State to track initialization

  // Hardcoded Blockfrost API Key (For Development Only)
  const blockfrostApiKey = process.env.REACT_APP_BLOCKFROST_API_KEY; // Replace with your actual API key

  // Initialize Lucid on mount
  useEffect(() => {
    const initializeLucid = async () => {
      console.log('Initializing Lucid with hardcoded API key...');
      try {
        console.log('Blockfrost API Key:', blockfrostApiKey ? 'Provided' : 'Missing');

        if (!blockfrostApiKey) {
          console.error('Blockfrost API key is missing.');
          setIsInitializing(false);
          return;
        }

        const lucidInstance = await Lucid.new(
          new Blockfrost('https://cardano-mainnet.blockfrost.io/api/v0', blockfrostApiKey),
          'Mainnet' // Specify the network here
        );

        setLucid(lucidInstance);
        console.log('Lucid initialized successfully:', lucidInstance);
      } catch (error) {
        console.error('Failed to initialize Lucid:', error);
      } finally {
        setIsInitializing(false);
        console.log('Lucid initialization process completed.');
      }
    };
    initializeLucid();
  }, [blockfrostApiKey]);

  // Function to connect wallet
  const connectWallet = async (walletKey) => {
    console.log(`Attempting to connect wallet: ${walletKey}`);
    const wallet = window.cardano && window.cardano[walletKey];
    if (!wallet) {
      console.error(`${walletKey} wallet not found.`);
      alert(`${walletKey} wallet not found. Please install it from the respective extension store.`);
      return;
    }

    if (!lucid) {
      console.error('Lucid is not initialized yet.');
      alert('Lucid is still initializing. Please try again in a few moments.');
      return;
    }

    try {
      console.log('Enabling wallet...');
      const walletApi = await wallet.enable();
      lucid.selectWallet(walletApi);

      console.log('Fetching wallet address...');
      const address = await lucid.wallet.address();
      setWalletAddress(address);
      setIsConnected(true);
      console.log('Wallet connected:', address);
    } catch (error) {
      console.error('Failed to connect wallet:', error);
      alert('Failed to connect wallet. Please try again.');
    }
  };

  // Function to disconnect wallet
  const disconnectWallet = () => {
    setWalletAddress(null);
    setIsConnected(false);
    console.log('Wallet disconnected');
  };

  return (
    <WalletContext.Provider
      value={{ lucid, walletAddress, isConnected, isInitializing, connectWallet, disconnectWallet }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
