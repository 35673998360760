import React, { useRef, useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip, CircularProgress } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import DownloadIcon from '@mui/icons-material/Download';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';

/**
 * Utility function to format numbers with comma separators and fixed decimal places.
 * @param {number} num - The number to format.
 * @returns {string} - Formatted number as a string.
 */
const formatNumber = (num) => {
  if (typeof num !== 'number') return num;
  return num.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
};

/**
 * Utility function to generate a timestamp for filenames.
 * @returns {string} - Current timestamp in ISO format, with colons and periods replaced.
 */
const getTimestamp = () => {
  const now = new Date();
  return now.toISOString().replace(/[:.]/g, '-');
};

/**
 * DownloadButton2 Component
 * Allows users to download transaction data as PNG, CSV, or XLSX.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.transactions - Array of transaction objects to download.
 */
const DownloadButton2 = ({ transactions }) => {
  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor
  const openMenu = Boolean(anchorEl); // Determines if the menu is open
  const exportRef = useRef(null); // Ref for the hidden div used in PNG export
  const [loading, setLoading] = useState(false); // Loading state for PNG export

  /**
   * Opens the download menu.
   * @param {Object} event - Click event.
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the download menu.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Exports the transaction data as a PNG image.
   * Utilizes html2canvas to capture the styled hidden div.
   */
  const exportPNG = async () => {
    try {
      setLoading(true);

      if (exportRef.current) {
        // Make the export div visible and reset positioning
        exportRef.current.style.visibility = 'visible';
        exportRef.current.style.position = 'relative';
        exportRef.current.style.top = '0';
        exportRef.current.style.left = '0';

        // Allow the browser to render the visibility changes
        await new Promise(resolve => setTimeout(resolve, 500));

        // Capture the export div as a canvas
        const canvas = await html2canvas(exportRef.current, { scale: 2, useCORS: true });
        const imgData = canvas.toDataURL('image/png');

        // Hide the export div again
        exportRef.current.style.visibility = 'hidden';
        exportRef.current.style.position = 'absolute';
        exportRef.current.style.top = '-10000px';
        exportRef.current.style.left = '-10000px';

        // Create a temporary link to initiate the download
        const link = document.createElement('a');
        link.href = imgData;
        link.setAttribute('download', `transactions_${getTimestamp()}.png`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error exporting as PNG:', error);
      alert('Failed to export transactions as PNG.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Exports the transaction data as a CSV file.
   * Converts the transactions array into CSV format.
   */
  const exportCSV = () => {
    try {
      const csvRows = [];
      const headers = Object.keys(transactions[0]);
      csvRows.push(headers.join(','));

      for (const transaction of transactions) {
        const values = headers.map(header => {
          const val = transaction[header];
          if (Array.isArray(val)) {
            return `"${val.join('; ')}"`; // Handle arrays
          } else if (typeof val === 'object') {
            return `"${JSON.stringify(val)}"`; // Handle objects
          } else {
            return `"${val}"`;
          }
        });
        csvRows.push(values.join(','));
      }

      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `transactions_${getTimestamp()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting as CSV:', error);
      alert('Failed to export transactions as CSV.');
    }
  };

  /**
   * Exports the transaction data as an XLSX file.
   * Utilizes the xlsx library to convert JSON data to Excel format.
   */
  const exportXLSX = () => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(transactions);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

      XLSX.writeFile(workbook, `transactions_${getTimestamp()}.xlsx`);
    } catch (error) {
      console.error('Error exporting as XLSX:', error);
      alert('Failed to export transactions as XLSX.');
    }
  };

  return (
    <div>
      {/* Download Button with Tooltip */}
      <Tooltip title="Download Transactions">
        <IconButton
          onClick={handleClick}
          sx={{
            backgroundColor: '#FF4500',
            color: '#FFFFFF',
            fontFamily: "'Schoolbell', cursive",
            boxShadow: '1px 1px 0px #000',
            '&:hover': {
              backgroundColor: '#e03e00',
              boxShadow: '2px 2px 0px #000',
            },
            transition: 'background-color 0.3s, box-shadow 0.3s',
          }}
          aria-label="download transactions"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : <GetAppIcon />}
        </IconButton>
      </Tooltip>

      {/* Download Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => { handleClose(); exportPNG(); }}>
          <DownloadIcon fontSize="small" style={{ marginRight: '8px' }} />
          Download as PNG
        </MenuItem>
        <MenuItem onClick={() => { handleClose(); exportCSV(); }}>
          <DownloadIcon fontSize="small" style={{ marginRight: '8px' }} />
          Download as CSV
        </MenuItem>
        <MenuItem onClick={() => { handleClose(); exportXLSX(); }}>
          <DownloadIcon fontSize="small" style={{ marginRight: '8px' }} />
          Download as XLSX
        </MenuItem>
      </Menu>

      {/* Hidden Div for PNG Export */}
      <div
        ref={exportRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          top: '-10000px',
          left: '-10000px',
          width: '800px', // Adjust width as needed
          backgroundColor: '#FFF8DC',
          border: '2px solid black',
          borderRadius: '10px',
          boxShadow: '1px 1px 5px rgba(0,0,0,0.3)',
          fontFamily: "'Schoolbell', cursive",
          padding: '20px',
        }}
      >
        {/* Header Section */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <h2 style={{ color: '#FF4500', margin: 0 }}>Transaction Information</h2>
          <img
            src="/ermo.jpg" // Ensure this path is correct and the image exists in the public directory
            alt="Ermo"
            style={{
              width: '80px',
              height: '80px',
              border: '3px solid black',
              borderRadius: '50%',
            }}
          />
        </div>

        {/* Timestamp */}
        <p style={{ textAlign: 'center', marginBottom: '20px' }}>
          Downloaded on: {new Date().toLocaleString()}
        </p>

        {/* Categories Section */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
          {/* Description Category */}
          <div>
            <h3 style={{ color: '#FF4500', marginBottom: '10px' }}>Description</h3>
            <p>{transactions[0].Description}</p>
          </div>

          {/* Available Results Category */}
          <div>
            <h3 style={{ color: '#FF4500', marginBottom: '10px' }}>Available Results</h3>
            <p>
              {Array.isArray(transactions[0]['Available Results'])
                ? transactions[0]['Available Results'].join(', ')
                : transactions[0]['Available Results']}
            </p>
          </div>

          {/* Bet Balance Category */}
          <div>
            <h3 style={{ color: '#FF4500', marginBottom: '10px' }}>Bet Balance</h3>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
              {Object.entries(transactions[0]['Bet Balance']).map(([asset, balance]) => (
                <div key={asset} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img
                    src={`/${asset.toLowerCase()}.png`} // Ensure image paths are correct
                    alt={asset}
                    style={{
                      width: '30px',
                      height: '30px',
                      border: '1px solid black',
                      borderRadius: '50%',
                    }}
                  />
                  <span>{`${asset}: ${formatNumber(parseFloat(balance))}`}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Result Statistics Category */}
          <div>
            <h3 style={{ color: '#FF4500', marginBottom: '10px' }}>Result Statistics</h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              {Object.entries(transactions[0]['Result Statistics']).map(([result, assets], index) => (
                <div key={index}>
                  <h4 style={{ marginBottom: '5px' }}>{result}</h4>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {Object.entries(assets).map(([asset, data], idx) => (
                      <div key={idx} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <img
                          src={`/${asset.toLowerCase()}.png`} // Ensure image paths are correct
                          alt={asset}
                          style={{
                            width: '25px',
                            height: '25px',
                            border: '1px solid black',
                            borderRadius: '50%',
                          }}
                        />
                        <span>{`${asset}: ${formatNumber(parseFloat(data.total))}`}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Multipliers Category */}
          <div>
            <h3 style={{ color: '#FF4500', marginBottom: '10px' }}>Multipliers</h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              {Object.entries(transactions[0].Multipliers).map(([result, assets], index) => (
                <div key={index}>
                  <h4 style={{ marginBottom: '5px' }}>{result}</h4>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {Object.entries(assets).map(([asset, multiplier], idx) => (
                      <div key={idx} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <img
                          src={`/${asset.toLowerCase()}.png`} // Ensure image paths are correct
                          alt={asset}
                          style={{
                            width: '25px',
                            height: '25px',
                            border: '1px solid black',
                            borderRadius: '50%',
                          }}
                        />
                        <span>{`${asset}: x${formatNumber(parseFloat(multiplier))}`}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// PropTypes for type checking
DownloadButton2.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      Description: PropTypes.string.isRequired,
      'Available Results': PropTypes.arrayOf(PropTypes.string).isRequired,
      'Bet Balance': PropTypes.object.isRequired,
      'Result Statistics': PropTypes.object.isRequired,
      Multipliers: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default DownloadButton2;
